.junioreemployer_admin_formcontainer{
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.junioremployer_admin_form{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:1.5rem;
    margin-top: 2rem;
    /* border: 2px solid red; */

}