.editpopup_container {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0%;
    left: 0rem;
    z-index: 999;
    margin: auto;
    background: rgba(32, 32, 32, .7);
}

.EP_container {
    width: 50%;
    height: 95%;
    background-image: url("../../../public/employer/background.jpeg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 50vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 12px;


}

.EPcontainer_content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}
.close_btn{
    font-size: 1.5rem;
    border: none;
    background: transparent;

}
.edit_btn {
    width: 90%;
    display: flex;
    justify-content: space-between;
}

.edit_btn .EDIT_yes {
    width: 25%;
    border-radius: 10px;
    background: #FFF;
    border: 3px solid #F7701D;
    padding: .4rem;
    font-size: 17px;

}

.EDIT_yes:hover {
    background: rgba(247, 112, 29, 0.39);

}

.EPcontainer_content p {
    font-weight: 600;
    font-size: 20px;
}