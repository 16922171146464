.log_history_main_content{
    display: flex;
    flex-direction: row;
}
.log_history_content{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
}
.LogHistory{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* background-image: url(''); */
    background-repeat: no-repeat;
    background-position: left;

}
.loghistory{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 3rem;
}
.loghistory .log-head{
    width: 100%;
    align-items: flex-start;
}
.loghistory .log-head p{
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    display: flex;
    align-items: center;
}
.loghistory .Log-tabl{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    height: auto;
    /* border: 3px solid green; */
}

.Log-tabl table, th, td {
    border: 2px solid  rgba(0, 0, 0, 0.25);
    border-top: none;
    border-right: none;
    border-collapse: collapse;
    width: 150px;
    
}
th, td {
    /* padding:25px; */
    text-align: center;
    
}
.history_box_heading{
    height: 50px;
}
.history_box{
    height: 50px;
}
th{
    background: #ffff;
}
table#table {
    width: 100%;    
    background-color: rgba(140, 221, 220, 0.5) ;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
}