.Employeesubscription_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-image: url(../../assets/subscriptionheader.png); */
  background-image: url("../../../public/employer/subscriptionheader.png");
  background-position: top;
  background-repeat: no-repeat;
  /* margin-top: 2rem; */
  /* margin: auto; */
  font-family: "Poppins", sans-serif;
  /* border: 2px solid black; */
}
.Employeesubscription_header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
 
  color: white;
  /* border: 2px solid black; */
  margin-top: .5rem;
  
}
.Employeesubscription_header h1{
  /* border: 2px solid black; */
  font-size: 2rem;
  font-weight: 600;
}
.Employeesunscription_card {
  width: 100%;
}
.Employeesubscriptionpage_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 2rem;
  margin-bottom: 1rem;
  /* border: 2px solid black; */
}
.Employeesubscriptionpage_btn .proceedwith_without_subscriptionE {
  padding: 1rem;
  border: none;
  background: rgba(247, 112, 29, 0.39);
  cursor: pointer;
  border-radius: 7px;
  font-size: 1.2rem;
  margin-top: 2rem;
}
@media only screen and (max-width:768px) {

  .Employeesubscription_header h1{
    font-size: 1.6rem;
  }
}

@media only screen and (max-width:500px) {y

  .Employeesubscription_header h1{
    font-size: 1.4rem;
  }

  .Employeesubscriptionpage_btn{
    width: 85%;
  }
}