.blog_container{
    width: 50%;
    height: 50%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:25%;
    left:25%;
    border: none;
    outline: none;
    margin: auto;
    /* border: 2px solid navy; */
}
.blog_content{
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem 1rem 0.5rem 1.5rem;
}
.blogCloseBtn{
    display: flex;
    justify-content: flex-end;
}
.blog_popup_close_btn{
    border: none;
    background: transparent;
    font-size: 25px;
    /* font-weight: 500; */
    font-weight: 700;
    margin: 0rem 0.5rem -3rem 0rem;
    cursor: pointer;
}
.blog_main_content{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}
.blog_box1{
    width: 60%;
    display: flex;

    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
/* border: 2px solid red; */
}
.blog_heading{
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    margin-top: -8px;
    color: #000000;
    display: flex;
    font-size: 1.2rem;
    /* border: 5px solid green; */

}
.Bloginputtextarea{
    width: 100%;
    padding: .7rem;
       border: 2px solid #F77010;

    /* height: 180px; */
    border-radius: 12px;
    outline: none;
    margin: 1rem 0rem 1.0rem 0rem;

}
.blog_box2{
    width: 40%;

}
.blog_box2 img{
    width: 100%;
    height: 100%;

    /* height: ; */
    padding: 1.5rem 0rem 2.5rem 0rem;
}
.blogpopup_btn{
    display: flex;
    /* flex-direction: row; */
    align-items: center;
    /* justify-content: center; */
    margin:auto;
    background: rgba(247, 112, 29, 0.39);
    border-radius: 10px;
    width: 150px;
    font-size: 15px;
    /* text-align: center; */
    justify-content: center;
    border: none;
    font-weight: bold;
    font-family: "Poppins";
    cursor: pointer;
    padding: .7rem;
    /* margin-left: .1rem; */
    /* height: 47px; */
}
@media only screen and (min-width:901px) and (max-width:1280px){
    .blog_box1{
        width: 50%;
        display: flex;
    height: auto;
    /* margin-top: -.9rem; */
    margin-bottom: 1.5rem;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
    /* border: 2px solid red; */
    }
    /* .blog_main_content{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    
    } */
    .blogpopup_btn{
        width: 40%;
    }
    .blog_heading{
        /* width: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* line-height: 1rem; */
        font-size: 1.4rem;
    }

    .blog_container{
        gap: .5rem;
        width: 80%;
        height: 65%;
        left: 13%;
        bottom: 20%;
    }


  
}
@media only screen and (min-width:601px) and (max-width:900px){
    .blog_box1{
        width: 90%;
        display: flex;
    height: auto;
    margin-top: -.9rem;
    margin-bottom: 1.5rem;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
    /* border: 2px solid red; */
    }
    .blog_main_content{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    
    }
    .blogpopup_btn{
        width: 40%;
    }
    .blog_heading{
        /* width: 100%; */
        display: flex;
        justify-content: center;
        align-items: center;
        /* line-height: 1rem; */
        font-size: 1.4rem;
    }

    .blog_container{
        width: 80%;
        height: 80%;
        left: 13%;
        bottom: 40%;
        /* padding: .5rem; */
    }


}
  /* @media only screen and (min-width:400px) and (max-width:600px){
  
  } */
  @media only screen and (min-width:401px) and (max-width:601px){
    .blog_box1{
        width: 90%;
        display: flex;
    height: auto;
    margin-top: -.9rem;
    margin-bottom: 1.5rem;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
    /* border: 2px solid red; */
    }
    .blog_main_content{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    
    }
    .blogpopup_btn{
        width: 65%;
    }
    .blog_heading{
        /* width: 100%; */
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* line-height: 1rem; */
        font-size: 1.2rem;
    }

    .blog_container{
        width: 90%;
        height: 70%;
        left: 4%;
        bottom: 28%;
    }


}
  
  @media only screen and (min-width:201px) and (max-width:401px){
    .blog_box1{
        width: 90%;
        display: flex;
    height: auto;
    margin-top: -.9rem;
    margin-bottom: 1.5rem;
        flex-direction: column;
        justify-content: center;
        /* align-items: center; */
    /* border: 2px solid red; */
    }
    .blog_main_content{
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
    
    }
    .blogpopup_btn{
        width: 75%;
    }
    .blog_heading{
        /* width: 100%; */
        display: flex;
        /* justify-content: center; */
        align-items: center;
        /* line-height: 1rem; */
        font-size: 1.2rem;
    }

    .blog_container{
        width: 90%;
        height: 65%;
        left: 5%;
        bottom: 28%;
    }
    
  }