.refer_createprofile_feedback_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.refercard_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(247,112,29,0.39);
    border-radius: 20px;
    /* padding: .5rem; */
    margin-top: 1rem;
    padding:  2rem;
         /* border: 2px solid black; */

    
    
}
.RC_left{
    width: 65%;
    display: flex;
    margin: 1rem 0rem 0rem 1rem;
     flex-direction: column; 
     justify-content: space-around;
     align-items: center;
     /* border: 3px solid green; */
    /* justify-content: center;
    align-items: center;  */
}
.RC_left h2{
    font-size: 1.8rem;
    
}
.RC_left .refer{
    width: 16%;
    border: none;
    color:#ffff;
    background: #F7701D;
    border-radius: .3rem;
    padding: .5rem;
}
.RC_right{
    padding: .7rem;
    /* width: 100%;
    height: 100%; */
    width: 35%;
    display: flex;
    justify-content: center;
    /* border: 2px solid black; */
}
.RC_right img{
    width: 40%;
    /* border: 2px solid black; */
}
@media only  screen and (max-width:830px) {
    .RC_left h2{
        font-size: 1.5rem;
        
    } 
    .RC_right img{
        width: 50%;
        /* border: 2px solid black; */
    }
    .RC_left .refer{
        width: 20%;
    }
}
@media only  screen and (max-width:450px) {
    .RC_right{
        display: none;
    }
    .RC_left{
        width: 100%;
    }
    .RC_left h2{
        font-size: 1.3rem;
        margin-top: 0;
        
    } 
    .RC_left .refer{
        width: 25%;
    }

}
