.dialog_desc{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 30vh;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to right bottom, #FFFFFF,#5BCECD);
    gap: 2rem;
    padding: 1rem;
}