.subflipingcard_main {
    /* position: absolute; */
    width: 60%;
    height: 80%;
    top:10%;
    left: 20%;
    position: fixed;
    transform-style: preserve-3d;
    transition: all 2s linear;
  
    /* margin-top: 2rem; */
    /* border: 3px solid yellow; */
  }
  .price_plan{
    width: 100%;
    height: 80%;
    display: flex;
    flex-direction: row;
    /* border: 3px solid green; */
    justify-content: space-between;
  }
  .price_sub_plan{
    width: 48%;
    /* border: 3px solid black; */
    /* text-align: justify; */
    padding: 1rem;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
    justify-content: space-around;
    border-radius: 7px;
    align-items: center;
  }
  .front_btn_right{
    width: 100%;
    display: flex;
    flex-direction: row;
    /* margin-top: 1rem; */
    justify-content: space-between;
  }
  .front_btn_rightsecond{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* margin-top: 1rem; */

  }