.edu_cont_main{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:0%;
    left:0rem;
    z-index: 999;
    margin: auto;
    background: rgba(32,32,32,.7);


}
.education_cont{
    top: 15%;
    left: 25%;
    width: 50%;
    height: 70%;
    position: fixed;
    background-color: white;
    background-image: linear-gradient(to right bottom, #FFFFFF, rgba(21, 185, 184, 0.6));
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 12px;


}
.education_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: scroll;
    height: 98%;
    border-radius: 10px;

}
.Education{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    gap: 1rem;
    height: 95%;
}
.Edufield_input_scroll{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 40vh;
    overflow-y: scroll;

}
.Edufield_input{
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 75%;
}
.Education h2{
    text-align: center;
}
.button_e_ss{
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
}
.button_e{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem 0rem 1rem 0rem;
}
.btn_e_ss{
    width: 15%;
    border: none;
    background: #F7701D;
    border-radius: 4px;
    padding: .4rem;
    color: #ffff;
}
@media only screen  and (max-width: 1024px) {
    .education_cont{
        width: 90%;
      }  
     
}
@media only screen  and (max-width: 650px) {
  .education_cont{
    width: 90%;
  } 
  .Education{
    padding: 0rem;
  } 
  .btn_e_ss{
    width: 25%;
    padding: .6rem;
  }
}