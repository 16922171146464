.Footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.image_header{
  width: 100%;
  background-image: linear-gradient(white, #e0f6f6);
}
.footer_img{
    width: 100%;
    height: 100%;
}
.Footer .topimg {
  width: 100%;
  position: relative;
  top: 8.5rem;
}
.Footer .footer {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Footer .footer .Foot {
  display: flex;
  flex-direction: column;
  background: #e0f6f6;
  width: 100%;
  align-items: center;
  justify-content: space-around;
  background-repeat: no-repeat;
  height: auto;
}
.Footer .footer .Foot .card {
  width: 100%;
  margin-top: 6rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 1.5rem;
}
.card .card-1 {
  color: #464353;
  text-align: center;
  align-items: flex-start;
  justify-content: flex-start;
}
.card .card-2 .icons {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.card .card-1 .icons a {
  text-decoration: none;
  color: #464353;
  margin-left: 1rem;
  font-size: xx-large;
}

.Footer .footer .Foot .card .card-2 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.card-2 a {
  color: #464353;
  text-decoration: none;
}

.Foot .card .card-3 {
  color: #464353;

}
.connect_us_heading {
  color: #464353;
}
.card_3_main_head {
  color: #464353;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
}
.card_3_main_sub_head {
  color: #464353;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

@media only screen and (min-width: 1201px) and (max-width: 1400px) {
  .Footer .footer .Foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    /* background-image: url('../../../public//jobseeker/Vector.svg'); */
    background-repeat: no-repeat;
    /* background-position: ; */
    /* border: 13px solid green; */
    height: auto;
  }
  .Footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    /* border: 3px solid blue; */
  }
  .footer_social_icons{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    width: 100%;
  }
  .card .card-1 {
    width: 16%;
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content: flex-start;
    /* border: 3px solid red; */
  }
  .Footer .footer .Foot .card .card-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    /* border: 3px solid yellow; */
  }
  .Foot .card .card-3 {
    color: white;
    /* border: 3px solid black; */
    /* text-align: justify; */
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}
@media only screen and (min-width: 1001px) and (max-width: 1200px) {
  .Footer .footer .Foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    /* background-image: url('./Foot-img/Vector.svg'); */
    background-repeat: no-repeat;
    /* background-position: ; */
    /* border: 3px solid green; */
    height: auto;
  }
  .Footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    /* border: 3px solid blue; */
  }
  .card .card-1 .icons {
    display: flex;
    flex-direction: row;
  }
  .card .card-1 {
    color: white;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 3px solid red; */
  }
  .Footer .footer .Foot .card .card-2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    /* border: 3px solid yellow; */
  }
  .Foot .card .card-3 {
    color: white;
    /* border: 3px solid black; */
    /* text-align: justify; */
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
  .footer_social_icons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .footer_social_icons a{
    transform: scale(.5);
  }
}

@media only screen and (min-width: 901px) and (max-width: 1000px) {
  .Footer .footer .Foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    /* background-image: url('./Foot-img/Vector.svg'); */
    background-repeat: no-repeat;
    /* background-position: ; */
    /* border: 3px solid green; */
    height: auto;
  }
  .Footer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5rem;
    /* border: 3px solid blue; */
  }
  .Footer .footer .Foot .card {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 8rem;
  }
  .card .card-1 .icons {
    display: flex;
    flex-direction: row;
  }
  .footer_social_icons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .footer_social_icons a{
    transform: scale(.8);
  }
}
@media only screen and (min-width: 801px) and (max-width: 900px) {
  .Footer .footer .Foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    /* background-image: url('./Foot-img/Vector.svg'); */
    background-repeat: no-repeat;
    /* background-position: ; */
    /* border: 3px solid green; */
    height: auto;
  }
  .Footer .footer .Foot .card {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 6rem;
  }
  .card .card-1 .icons {
    display: flex;
    flex-direction: row;
  }
  .footer_social_icons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .footer_social_icons a{
    transform: scale(.8);
  }
}
@media only screen and (min-width: 701px) and (max-width: 800px) {
  .Footer .footer .Foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    /* background-image: url('./Foot-img/Vector.svg'); */
    background-repeat: no-repeat;
    /* background-position: ; */
    /* border: 3px solid green; */
    height: auto;
  }
  .Footer .footer .Foot .card {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 4rem;
  }
  .card .card-1 .icons {
    display: flex;
    flex-direction: row;
  }
  .footer_social_icons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .footer_social_icons a{
    transform: scale(.8);
  }
}
@media only screen and (min-width: 601px) and (max-width: 700px) {
  .Footer .footer .Foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    /* background-image: url('./Foot-img/Vector.svg'); */
    background-repeat: no-repeat;
    /* background-position: ; */
    /* border: 3px solid green; */
    height: auto;
  }
  .Footer .footer .Foot .card {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 2rem;
  }
  .card .card-1 .icons {
    display: flex;
    flex-direction: row;
  }
  .footer_social_icons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .footer_social_icons a{
    transform: scale(.8);
  }
}
@media only screen and (min-width: 201px) and (max-width: 580px) {
  .Footer .footer .Foot {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    /* background-image: url('./Foot-img/Vector.svg'); */
    background-repeat: no-repeat;
    /* background-position: ; */
    /* border: 3px solid green; */
    height: auto;
  }
  .Footer .footer .Foot .card {
    margin-top: 6rem;
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    align-items: center;
    gap: 0.3rem;
  }
  .card .card-1 .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  .card-1 img {
    width: 60%;
    height: 60%;
  }
  .card-1 h3 {
    font-size: 12px;
  }
  .card .card-1 .icons a {
    text-decoration: none;
    color: white;
    margin-left: 0.3rem;
    transform: 1;
    padding: 0.2rem;
    font-size: small;
  }
  .card-2 a {
    font-size: 10px;
  }
  .card-3 h2 {
    font-size: 15px;
    line-height: normal;
  }
  .card-3 h3 {
    font-size: 12px;
    line-height: 22px;
  }
  .footer_social_icons{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .footer_social_icons a{
    transform: scale(.5);
  }
}
@media only screen and (min-width: 201px) and (max-width: 300px) {
  .navbar {
    gap: 0.7rem;
  }
  .navbar a {
    font-size: 12px;
  }
  .navbar button {
    width: 120%;
    font-size: 12px;
  }
}
