.employer_dropdown_content{
    position: absolute;
    top: 36.7%;
    left: 75%;
    transform: translate(-50%, -50%);
    width: 21%;
    background-color: white;
    outline: none;
    border-radius:7px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    background: #F2F2F2;
}

.employer_dropdown_outline{
    outline: none;
    display: block;

}
.hide_dropdown{
    display: none;
}

@media only screen and (min-width: 200px) and (max-width: 580px){

    .employer_dropdown_content{
        width: 90%;
        top: 25%;
        left: 50%;
    }


}