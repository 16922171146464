.DashBoardTour-background12{
  width: 40%;
  display: flex;
  position: fixed;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  left: 32.7rem;
  top: 25.8rem;
}