.starthiring_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    font-style: normal;
}
.starthiring_content{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    /* border: 2px solid red; */
}
.starthiring_left{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 0rem;
}
.starthiring_btn{
    width: 90%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.hiring{
    width: 20%;
    border: none;
    background: #F7701d;
    border-radius: .3rem;
    padding: .5rem;
    color: #fff;
    margin-top: 1rem;

}
.starthiring_right{
    margin-top: 1rem;
    /* border: 2px solid black; */
    padding: 1rem;
}
  

.starthiring_right img{
    width: 65%;
    /* border: 2px solid black; */
}
@media only  screen and (max-width:830px){
    .starthiring_left{
        width: 60%;
        margin-left: 2rem;
    }
    .starthiring_right{
        display: flex;
        justify-content: center;
    }

    .hiring{
        width: 25%;
    }


}
@media only  screen and (max-width:450px)
{
    .starthiring_left{
        width: 100%;
        margin-left: 2rem;
    }
    .starthiring_right{
        display: none;
        
    }
    .hiring{
        width: 40%;
    }
}