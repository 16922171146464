.Qform {
  width: 400px;
  height: 400px;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 3px solid yellow; */
  /* top: 15%; */
}

.background_img_manage_profile {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-image: url("../../../public/employer/backgroundnewnewimg.jpg");
  background-position: right bottom;
  background-repeat: no-repeat;
}

.progressbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 30px;
  /* border: 3px solid green; */
  /* border: 3px solid blue; */
}

.box1 {
  display: flex;
  height: 15%;
  /* height: 25px; */
  flex-direction: row;
  width: 100%;
  /* justify-content: space-evenly; */
  /* border: 5px solid red; */

}

.default {
  width: 18%;
  background-color: #F7701D;
  border-radius: 0px 15px 15px 0px;
  height: 25px;
}

.proactive1 {
  width: 18%;
  background-color: rgba(91, 206, 205, 0.7);
  border-radius: 0px 15px 15px 0px;
  height: 25px;

}

.form-containers_profile {
  width: 47%;
  height: 70vh;
  /* height: 80vh; */
  margin-top: 2.5rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  display: flex;
  left: 30%;
  position: fixed;
  top: 20%;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.form-container .header {
  flex: 20%;
  display: grid;
  place-items: center;
}

.body {
  width: 80%;
  height: auto;
}

.footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

@media only screen and (min-width:901px) and (max-width:1280px) {
  .form-containers_profile {
    width: 90%;
    height: auto;
    position: initial;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .autocompleteareainterest {
    width: 20%;
  }

  .p1_main_form {
    overflow-y: scroll;
    height: 20vh;

  }

  .p3_main {

    height: 50vh;
  }

  .footer {
    padding: .5rem;
  }



}

@media only screen and (min-width:601px) and (max-width:900px) {
  .form-containers_profile {
    width: 50%;
    left: 25%;
    top: 25%;
  }

  .form-containers_profile {
    width: 90%;
    height: 80vh;
    position: initial;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .autocompleteareainterest {
    width: 20%;
  }

  .p1_main_form {
    overflow-y: scroll;
    height: 20vh;

  }

  .footer {
    padding: .5rem;
  }



}

@media only screen and (min-width:201px) and (max-width:600px) {

  .background_img_manage_profile {
    height: 100vh;
    align-items: center;
  }
  .form-containers_profile {
    width: 90%;
    top: 8%;
    position: fixed;
    bottom: 10%;
    height: auto;
    left: 0%;
    margin-left: 0%;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .autocompleteareainterest {
    width: 20%;
  }

  .p1_main_form {
    overflow-y: scroll;
    height: 20vh;

  }

  .p3_main {

    height: 60vh;
  }

  .footer {
    padding: .5rem;
  }

}