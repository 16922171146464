.mail_main_content{
    display: flex;
    flex-direction: row;
}
.mail_content{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
}
.MailMessage{
    width: 95%;
    margin-top: 1.5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 75vh;
    overflow-y: scroll;
    background-repeat: no-repeat;
    background-position: left;

}
.MailMessage .mailmessage{
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.mailmessage .Mail-head{
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    align-items: center;
}
.mailmessage .Mail-icon{
    margin-top: -1rem;
    font-size: 5rem;
    margin-bottom: -0rem;
}
.mailmessage .Mail-new{
    width: 100%;
    padding-left: 1rem;
    height: 62px;
    border-radius: 15px 15px 0px 0px;
    background: rgba(140, 221, 220, 0.5);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    text-align: justify;
    border: none;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
}
.mailmessage .Mail-id{
    width: 100%;
    height: 62px;
    padding-left: 1rem;
    border: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mailmessage .Mail-id::placeholder{
    font-size: large;
    color: black;
}
.mailmessage .v1{
    border: 2px solid gray;
    width: 100%;
}
.mailmessage .Mail-msg{
    width: 100%;
    height: 152px;
    border: none;
    padding-left: 1rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.mailmessage .Mail-msg::placeholder{
    font-size: larger;
    color: black;
    position: relative;
    top: -  3rem;
}
.mailmessage .Mail-Btn{
    width: 197px;
    height: 45px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin-top: 2rem;
    border: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #000000;
}