.view_freelancing{
    width:40%;
    display:flex;
    flex-direction: column;
    background: #FFFFFF;
    position: fixed;
    top: 15%;
    left: 30%;
    border-radius: 7px;
    /* height: 70%; */
}
.view_freelance_content{
    width: 100%;
    /* height: 100%; */
    /* background-image: linear-gradient(to right bottom, 
    #FFFFFF, rgba(117, 231, 216, 0.6)) ; */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

}
.project_heading{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem 0rem 1rem;
}
.project_details{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 2px solid green; */
    padding: 0rem 1rem 0rem 1rem;
    
}
.project_skills{
    display: flex;
    flex-wrap: wrap;
    padding: 0rem 1rem 0rem 1rem;
    gap: 1rem;

}
.project_owner{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0rem 1rem 1.5rem 1rem;
    gap: 0.2rem;
}
.freelance_para{
    margin: 0px;
    font: 0.5rem;
}