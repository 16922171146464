.create_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}
.createprofile_container{
    width: 100%;
    /* height: 280px; */
    display: flex;
    flex-direction: row;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(247,112,29,0.39);
    border-radius: 20px;
    padding: 1rem;
}
.CP_left{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 5px solid green; */
    /* margin: -3.5rem 0rem 0rem -2rem; */
    padding: 0rem 0rem 0rem 2rem;
    width: 70%;
    /* justify-content: flex-start; */
    /* padding: 2rem; */
}
.CP_Head{
    line-height: 35px;
    font-weight: 600;
    font-size: 26px;
    margin: 0.7rem 0rem 0rem 0rem ;
    /* text-align: justify; */
}
.create_refer_conten{
    font-weight: 500;
    margin-top: 1rem;
    font-family: "Poppins",sans-serif;
    line-height: 29px;
    font-style: normal;
    font-size: 18px;
    text-align: justify;
}
.CP_right{
    width: 30%;
    /* border: 5px solid blue; */
    /* margin: 3rem 0rem 0rem 0rem; */
    /* padding: .7rem; */
    
}
.CP_right img{
    /* border: 5px solid yellow; */
    width: 70%;
    height: 100%;

}

/* @media only screen and (min-width: 801px) and (max-width: 900px) {
    .createprofile_container{
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .CP_left{
        display: flex;
        flex-direction: column;
        text-align: justify;
        align-items: center;
        justify-content: center;
    }
  } */
  @media only screen and (min-width: 901px) and (max-width: 1100px) {
    .CP_left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* border: 5px solid green; */
        /* margin: -3.5rem 0rem 0rem -2rem; */
        /* padding: 0rem 0rem 0rem 2.8rem; */
        /* width: 70%; */
        /* justify-content: flex-start; */
        padding: 2rem;
    }
  }
  @media only screen and (min-width: 401px) and (max-width: 900px) {
    .createprofile_container{
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .CP_right {
        width: 100%;
        /* border: 5px solid blue; */
        /* margin: 3rem 0rem 0rem 0rem; */
        padding: 2rem;
    }
    .CP_right img {
        /* border: 5px solid yellow; */
        width: 60%;
        height: 30%;
    }
    .CP_left{
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 2rem;
    }
    .CP_left .create_btnn {
        width: 34%;
        height: 45px;
        border: none;
        /* padding: 0.2rem; */
        justify-content: center;
        font-family: "Poppins";
        background-color: #F7701D;
        color: #FFFF;
        border-radius: 10px;
        font-size: 20px;
    }
  }
  
  @media only screen and (min-width: 301px) and (max-width: 400px) {
    .createprofile_container{
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .CP_right {
        width: 95%;
        /* border: 5px solid blue; */
        /* margin: 3rem 0rem 0rem 0rem; */
        padding: 0.7rem;
    }
    .CP_left{
        display: flex;
        flex-direction: column;
        text-align: justify;
        align-items: center;
        justify-content: center;
        padding: 0%;
        width: 95%;
    }
    .CP_Head {
        line-height: 35px;
        font-weight: 600;
        font-size: 26px;
        margin: 0.7rem 0rem 0rem 0rem;
        text-align: center;
    }
    .CP_left h5 {
        /* color: #000000; */
        font-weight: 500;
        font-family: "Poppins",sans-serif;
        line-height: 29px;
        font-style: normal;
        text-align: center;
        font-size: 18px;
        margin: 0.5rem 0rem 0.5rem 0rem;
        /* line-height: 23px; */
        /* border: 2px solid red; */
    }
    .CP_left .create_btnn {
        width: 44%;
        height: 45px;
        border: none;
        /* padding: 0.2rem; */
        justify-content: center;
        font-family: "Poppins";
        background-color: #F7701D;
        color: #FFFF;
        margin: 1.5rem;
        border-radius: 10px;
        font-size: 20px;
    }
  }
  @media only screen and (min-width: 201px) and (max-width: 300px) {
    .createprofile_container{
        display: flex;
        flex-direction: column-reverse;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .CP_left{
        display: flex;
        flex-direction: column;
        text-align: justify;
        align-items: center;
        justify-content: center;
    }
  }