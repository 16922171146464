.choose_your_plans {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(217, 243, 243, 0.5);
}
.choose_heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  margin-top: 2rem;
  text-align: center;
}
.choose_sub_heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  text-align: center;
  line-height: 33px;
}
.choose-img_main {
  width: 80%;
  height: 80%;
}
.choose_plan_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* border: 3px solid yellow; */
  justify-content: space-between;
  margin: 3rem 0rem 5rem 0rem;

}
.choose_img {
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.choose_plan_card {
  width: 53%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4rem;
}
.basic_plan_card {
  background: linear-gradient(179.96deg, #8cdddc 0.04%, white 163.86%);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: center;
}
.Premium_plan_card {
  background: linear-gradient(179.96deg, #8cdddc 1%, white 150%);
  filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  border-radius: 10px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 35%;
  align-items: center;
}
.plan_name_heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: bolder;
  font-size: 22px;
  line-height: 43px;
}
.plan_price {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 28px;
}

@media only screen and (min-width: 601px) and (max-width: 1000px) {
  .choose_plan_content{
    flex-direction: column;
    gap: 4rem;
  }
  .choose_img{
    width: 80%;
  }
  .choose_plan_card{
    width: 100%;
    justify-content: space-evenly;
  }
}
@media only screen and (min-width: 201px) and (max-width: 600px) {
  .choose_your_plans{
    align-items: center;
    padding: 20% 0% 0% 0%;
  }
  .choose_img{
    width: 100%;
  }
  .choose_plan_content{
    flex-direction: column;
    gap: 2rem;
  }
  .choose_plan_card{
    width: 100%;
    flex-direction: column;
    gap: 2rem;
  }
  .basic_plan_card{
    width: 85%;
  }
  .Premium_plan_card{
    width: 85%;
  }
  

}
