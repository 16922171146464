.Gallery_Containerrs {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url(../../../.././../public/employer/card.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: "Poppins",sans-serif;


  /* background: rgba(217, 243, 243, 0.5); */
  align-items: center;
}
.what_client_main_head {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  /* identical to box height */

  color: #000000;
}
.All_Sliderss {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.Vertical_Slider {
  width: 70%;
  height: 100vh;
  /* background-color:black; */
  /* background: rgba(235, 187, 157, 0.39); */
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); */
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.SliderM {
  width: 100%;
  object-fit: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  -webkit-animation: scroll 40s linear infinite;
  animation: scroll 20s linear infinite;
}
.SliderM:hover {
  animation-play-state: paused;
}
@-webkit-keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-300px * 9 / 2));
  }
}

@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(-300px * 9 / 2));
  }
}
.Slides {
  width: 99%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-radius: 10px;
  align-items: center;
  perspective: 100px;
  box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
}
/* .Slides img{
    width: 100%;
    height:300px;
    border-radius: 10px;
    transition: transform 0.5s;
} */
.Slides img:hover {
  transform: translateZ(10px);
  border-radius: 10px;
}
.Slider_Crousel {
  width: 70%;
  height: 100vh;
  object-fit: cover;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Slide_Crousel {
  width: 100%;
  height: 100vh;
  border-radius: 10px;
}
.Slide_Crousel img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
@media only screen and (max-width: 480px) {
  .All_Sliderss {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Slider_Crousel {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Slide_Crousel {
    width: 100%;
    height: 60vh;
  }
  .Slide_Crousel img {
    width: 100%;
    object-fit: cover;
    object-position: center;
  }
  .Vertical_Slider {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .SliderM {
    width: 100%;
    object-fit: cover;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    -webkit-animation: scroll 40s linear infinite;
    animation: scroll 7s linear infinite;
  }
  @-webkit-keyframes scroll {
    0% {
      transform: translateX(200);
    }
    100% {
      transform: translateX(calc(-100px * 4.5));
    }
  }
  @keyframes scroll {
    0% {
      transform: translateX(200);
    }
    100% {
      transform: translateX(calc(-100px * 4.5));
    }
  }
  .Slides {
    width: 100%;
    min-width: 5rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    perspective: 100px;
  }
  .Slides img {
    width: 100%;
    height: 90%;
    object-fit: cover;
    /* transition: transform 1s; */
  }
}
