.upload_resume {
  width: 100%;
  margin: 3rem 0rem 3rem 0rem;
  display: flex;
  flex-direction: row;
}
.upload_resume_left {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload_resume_right {
  width: 55%;
}
.right_resume_content {
  width: 95%;
  height: 100%;
  background: rgba(140, 221, 220, 0.5);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 4rem;
}
.upload_cv_heading {
  font-size: 40px;
  font-style: normal;
  line-height: 60px;
  font-weight: 600;
  color: #000000;
}
.upload_cv_contetn {
  font-family: "Poppins",sans-serif;
  margin-top: 2rem;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

@media only screen and (min-width: 201px) and (max-width: 500px) {
  .upload_resume{
    margin: 0rem;
    flex-direction: column;
    align-items: center;
  }
  .upload_resume_left{
    width: 100%;
    padding: 0rem;
  }
  .resume_img{
    width: 100%;
  }
  .upload_resume_right{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .right_resume_content{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .upload_cv_heading{
    font-size: 1.3rem;
    line-height: normal;
    text-align: center;
  }
  .upload_cv_contetn{
    width: 100%;
    text-align: justify;
    line-height: normal;
    padding: 1%;
  }
  .css-1gklwp8-MuiButtonBase-root-MuiButton-root{
    width: 57%;
  }
}
