.freelancing_applay_content{
    display: flex;
    width: 40%;
    position: fixed;
    top: 8%;
    left: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 1.3rem;
    border-radius: 10px;
    background: #FFFFFF;
    background: linear-gradient(to right bottom,white, #99ebeb);
}
.freelancing_applay_contents{
    display: flex;
    width: 40%;
    position: fixed;
    top: 8%;
    left: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    gap: 1.3rem;
    border-radius: 10px;
    background: #FFFFFF;
    background: linear-gradient(to right bottom,white, #99ebeb);
}