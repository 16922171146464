.ind_cont_main{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:0%;
    left:0rem;
    z-index: 999;
    margin: auto;
    background: rgba(32,32,32,.7);


}
.industries_cont{
    width: 45%;
    height: 80%;
    top: 10%;
    left: 30%;
    background-color: white;
    display: flex;
    flex-direction: column;
    background-image: linear-gradient(to right bottom, #FFFFFF, rgba(21, 185, 184, 0.6));
    overflow-y: scroll;
    /* padding: 1rem; */
    position: fixed;
    border-radius: 12px;


}

.industry_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    /* margin: auto; */
    border-radius: 10px;
    /* box-shadow: 0px 4px 50px rgba(0,0,0,.25); */
    /* margin-top: 1rem; */


}
.industryy{
    width: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* margin-left: 1rem; */
  
}
.industryy{
    text-align: center;

}

.industry_ip_label{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 5%;
    gap: 1.5rem;

}

.button_i_ss{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 5rem;
    align-items: center;
    margin-top: 3rem;
    /* margin-bottom: 2rem; */
}
.btn_i_ss{
    width: 15%;
    border: none;
    background: #F7701D;
    border-radius: 4px;
    padding: .4rem;
    color: #ffff;
}
.Date{
    margin-top: 0.5rem;
    width: 90%;
    display: flex;
    flex-direction:row;
    /* gap: 5%; */
    justify-content: space-between;
}

@media only screen  and (max-width: 1024px) {
    .industries_cont{
        width: 90%;
      }  
     
}
@media only screen  and (max-width: 650px) {
  .industries_cont{
    width: 90%;
  } 
  .industryy{
    padding: 0rem;
  } 
  .FML-Name{
    gap: 0;
  }
  .btn_i_ss{
    width: 25%;
    padding: .6rem;
  }
}