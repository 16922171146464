.special_service {
  background-image: url("../../../public//jobseeker//background.png");
  background-position: right top;
  background-size: 15vw;
  background-repeat: no-repeat;
  background: linear-gradient(to right bottom, white, #99ebeb);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* margin: auto; */
  margin-top: 3rem;
  width: 55%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  padding: 1.2rem;
  border-radius: 7px;

}

.cv_content {
  margin-top: 0.7rem;
  width: 90%;
  /* border: 2px solid red; */
}

.cv_content p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  line-height: 1.5rem;
  text-align: justify;
}

.ss {
  background-image: url("../../../public//jobseeker//background.jpeg");
  background-position: right top;
  background-size: cover;
  background-repeat: no-repeat;
  /* border: 5px solid red; */
  height: 100vh;
}

@media only screen and (max-width:850px) {
  .special_service {
    width: 75%;
    text-align: center;
  }

  .cv_content p {
    font-size: 1rem;
    text-align: justify;
  }
}

@media only screen and (max-width:500px) {
  .special_service {
    width: 73%;
    text-align: center;
  }

  .cv_content p {
    font-size: .7rem;
    text-align: justify;
  }
}