.referfriend_popup_container {
    width: 90%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0%;
    left: 4rem;
    z-index: 999;
    margin: auto;
    background: rgba(32, 32, 32, .8);
}

.titleCloseBtn {
    display: flex;
    justify-content: flex-end;
}

.RFPP_container {
    width: 60%;
    height: 80%;
    position: fixed;
    top: 15%;
    left: 20%;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(31, 30, 30, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;

}

.REPP_container_content {
    width: 90%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -4rem;
    margin-left: 4rem;
    margin-bottom: 2rem;
}

.referral_img {
    width: 50%;
    height: 50vh;
    margin-right: 2.5rem;
    padding: 3rem;
}

.referral_img img {
    width: 100%;
    height: 100%;

}

.socialmedia_logo {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.Social_Media {
    border: none;
    background: #fff;
    cursor: pointer;
    transform: scale(.8);

}

.Social_Media_text {
    border: none;
    background: #fff;
    cursor: pointer;
    transform: scale(.7);

}

.input_refer_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 1rem;
}

.input_refer_btn input {
    padding: .5rem;
    border: 3px solid #F7701D;
}

.input_refer_btn .btn_refer {
    width: 20%;
    margin-top: 1.5rem;
    padding: .5rem;
    border: none;
    background: #F7701D;
    border-radius: 5px;
    color: #FFFFFF;
    cursor: pointer;
}

@media only screen and (min-width: 201px) and (max-width: 580px){
    .RFPP_container{
        width: 90%;
        left: 5%;
    }
    .REPP_container_content{
        width: 100%;
        margin-top: 0rem;
        margin-left: 0rem;
        margin-bottom: 0rem;
    }
    .referral_img {
        width: 95%;
        margin-right: 0rem;
        padding: 1rem;
    }
    .socialmedia_logo{
        width: 100%;
        flex-wrap: wrap;
        justify-content: space-evenly;
        gap: 0rem;
    }
    .input_refer_btn .btn_refer{
        width: 40%;
    }
}