.home_container_mainE {
    display: flex;
    flex-direction: column;
    font-family: "poppins", "sans-serif";
    background: #FFFF;
    background-repeat: no-repeat;
    background-position: top right;

}

.home_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin: auto;
    font-family: 'poppins', sans-serif;


}

.showcaseskill_container {
    width: 100%;
    /* height: 100%; */
    display: flex;
    height: 70vh;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    /* margin-top: -1rem; */
    background-image: url(../../../public/employer/ellipseskills.png);
    background-repeat: no-repeat;
    background-position: left;
    /* background-size: 50vh; */
    background-color: white;
    /* margin-top: -5rem; */
}

.leftside_container {
    width: 50%;
    display: flex;
    /* border: 2px solid green; */
    margin-left: 2rem;

}

.card_container_skills {
    width: 80%;
    /* height: auto; */
    /* border: 2px solid yellow; */
    margin-top: 1rem;
    line-height: 30px;
    margin-bottom: 3rem;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    background-color: #FFFF;
    font-family: 'poppins', sans-serif;
    gap: 1rem;
    padding: 1rem;

}

.skills_heading {
    gap: 1rem;
}

.skills_heading h3 {
    color: #F7701D
}

.rightside_container {
    position: relative;

    width: 50%;
    /* height: 50vh; */
    display: flex;

    justify-content: flex-end;
    align-items: flex-end;
    margin-bottom: 3rem;
    margin-top: 1rem;
}

.rightside_container img {
    position: absolute;
    top: -8rem;
    width: 100%;
}

.second {
    animation-name: fade;
    animation-iteration-count: infinite;
    animation-duration: 1s;
    animation-direction: alternate;
}

@keyframes fade {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 1;
    }

    75% {
        opacity: 0;
    }

    100% {
        opacity: 0;
    }
}

.refercard_container_main {
    width: 100%;
    display: flex;
    gap: .5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: rgba(217, 243, 243, 0.5);
    padding-bottom: 3.5rem;
    padding-top: 1.5rem;
    margin-bottom: -2rem;
}

.refer_hire_heading {
    display: contents;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.flexibleplan_container_main {
    width: 100%;
    display: flex;
    /* margin-left: 4rem; */
    margin-top: 1rem;
}

.starthiring_main_container {
    width: 100%;
    margin-top: 3rem;
    background: rgba(140, 221, 220, 0.5);


}

.homedashboard_main {
    width: 100%;
    display: flex;
}

.GET_overview_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: 1rem; */
    gap: 2rem;
    padding-bottom: 3rem;
    font-family: "Poppins", sans-serif;
    /* border: 2px solid black; */
    background-image: url(../../../public/employer/howapply.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* background: rgba(217,243,243,0.5); */
}

.employee_h1 {
    font-family: "poppins";
    /* font-size: 2.5rem; */
    font-weight: 600;
    /* border: 2px solid black; */
}


.GET_left {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    /* align-items:flex-start; */
    font-family: Poppins;
    /* border: 2px solid black; */
    margin-top: 0;
    margin-right: 6rem;
}

/* .get_heading{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
} */
.get_heading h2 {
    line-height: 35px;
    font-weight: 600;
    font-size: 24px;

}

.GET_left h2 {
    width: 100%;
    /* border: 2px solid black; */
    margin-top: -1rem;
    margin: 0;

    /* font-family: Poppins; */
}

.GET_left span {
    font-weight: 700;
}

.GET_right {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 3rem;
}

.registrationget {
    width: 70%;
    background: #3898E2;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: .7rem;
    color: #FFFFFF;
    text-align: center;
}

.loginget {
    background: #BC84CB;
    width: 70%;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: .7rem;
    color: #FFFFFF;
    text-align: center;

}

.applyget {
    width: 70%;
    text-align: center;
    background: #56D9B1;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: .7rem;
    color: #FFFFFF;

}

.img_container {
    /* border: 2px solid black; */
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.img_container img {
    width: 55px;
    height: 50px;
    position: absolute;
    top: 20px;
    left: 7px;
    /* left: 549px; */
    /* top: 4208px; */
    border-radius: 7px;
    padding: .6rem;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    /* border: 2px solid black; */
}

@media only screen and (max-width:1100px) {
    .showcaseskill_container {
        height: 50vh;
    }

}

@media only screen and (max-width:900px) {
    .bannercontents {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
    }

    .bannerleft_content {
        width: 100%;
    }

    .bannerright_content {
        width: 65%;
        margin-top: 2rem;
    }

    .brandingcontents {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0;
    }

    .brandingleft_content {
        width: 100%;
        /* border: 2px solid blue; */
        margin-left: 0;
    }

    .brandingright_content {
        width: 65%;
        margin-top: 2rem;
    }


    .GET_overview_container {
        flex-direction: column;
    }

    .GET_left {
        width: 100%;
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .GET_left h2 {
        text-align: center;
        font-size: 1.1rem;
    }

    .GET_right {
        width: 100%;
        margin-top: 1rem;
    }

    .showcaseskill_container {
        height: 50vh;
    }

    .rightside_container {
        width: 40%;
    }

    .card_container_skills {
        width: 91%;
    }



}

@media only screen and (max-width:590px) {
    .employee_h1 {
        font-size: 2rem;
        margin-top: 0;
    }

    .em_para {
        font-size: 1.1rem;
    }

    .employerbranding_container {
        margin-top: 0;
    }

    .brandingcontents {
        margin-left: 1.5rem;
    }

    .service_cards {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .sd {
        width: 80%;
    }

    .starthiring_main_container {
        padding: 1rem;
    }

    .GET_overview_container {
        gap: 1rem;
    }

    .GET_left {
        width: 100%;
        margin: auto;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .GET_left h2 {
        font-size: 1.2rem;
    }

    .GET_right {
        flex-direction: column;
        margin-bottom: 2rem;
    }

    .img_container {
        width: 60%;
    }

    .em_para {
        line-height: 20px;
        font-weight: 400;
        font-size: .8rem;
    }

    .showcaseskill_container {
        flex-direction: column;
        justify-content: center;
    }

    .showcaseskill_container {
        height: 70vh;
    }

    .leftside_container {
        width: 100%;
        flex-direction: column;
        align-items: center;
        margin-left: 0;
    }

    .card_container_skills {
        width: 80%;
    }

    .rightside_container {
        display: none;
    }
}
