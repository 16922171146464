.OwnCompany {
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0%;
  margin: 0rem 0rem 0rem 9rem;
  z-index: 999;
  margin: auto;
  /* background: rgba(32, 32, 32, 0.7); */
  background: rgb(100, 98, 98);
}
.owncompany_container {
  width: 50%;
  background-image: url("../../../public/employer/background.jpeg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  /* overflow-y: scroll; */
  margin-top: 1rem;

}
.job_desc_container{
  width: 50%;
  background-image: url("../../../public/employer/background.jpeg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  overflow-y: scroll;
  margin-top: 1rem;

}
.editor{
  width: 65%;
  height: auto;
  border-radius: 12px;
  /* border:2px solid  rgba(247, 112, 29, 0.39); */

}
.close_btn_own{
  width: 100%;
  padding-top: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 1rem;
}
.Owncompany {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

}
.owncompany_form{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.label_tag{
  width: 80%;
  /* border: 3px solid green; */
  margin: 0.5rem 0rem 1rem 0rem;
}

.Owncompany .own-head {
  font-weight: 500;
  font-size: 55px;
  color: #000000;
}
.own-head_desc{
  font-weight: 500;
  font-size: 35px;
  color: #000000;
  text-align: center;

}
.Owncompany .own-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  margin: auto;
}

.Owncompany .own-form label {
  font-style: normal;
  font-weight: 500;
  text-align: start;
  color: #898787;
}
@media only screen and (min-width:201px) and (max-width:1000px){
 /* .OwnCompany{
  height: 96%;
  top:7%;
 } */
  .owncompany_container {
    width: 95%;
    text-align: center;
    overflow: hidden;
    height: 95vh;
}
.job_desc_container{
  width: 95%;
    text-align: center;
    height: 95vh;
    border-radius: 12px;

}
.Owncompany .own-form {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: flex-start;
  margin: auto;
  /* border: 10px solid blue; */
}
}

