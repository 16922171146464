.Qform {
  width: 400px;
  height: 400px;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 3px solid yellow; */
    /* top: 15%; */
  }
  .progressbar{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;
    /* border: 3px solid green; */
    /* border: 3px solid blue; */
  }
.box1{
    display: flex;
    height: 15%;
    /* height: 25px; */
    flex-direction: row;
    width: 100%;
    /* justify-content: space-evenly; */
    /* border: 5px solid red; */

}
.default{
    width: 18%;
    background-color: #F7701D;
    border-radius: 0px 15px 15px 0px;
    height: 25px;
}
.proactive{
    width: 18%;
    background-color: green;
    border-radius: 0px 15px 15px 0px;
    height: 25px;

}
.form-containers {
    width: 40%;
    height: 400px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    display: flex;
    left: 30%;
    position: fixed;
    top: 15%;
    flex-direction: column;
    /* border: 3px solid green; */
    align-items: center;
    justify-content: space-evenly;
  }
  .form-container .header {
    flex: 20%;
    display: grid;
    place-items: center;
  }
  .body {
    width: 80%;
    height: auto;
    /* border: 3px solid green; */
  }
  
  .footer {
    width: 100%;
    /* flex: 20%; */
    display: flex;
    /* border: 3px solid red; */
    align-items: center;
    justify-content: space-around;
  }
  @media only screen and (min-width:601px) and (max-width:900px){
    .form-containers{
      width: 50%;
      left: 25%;
      top: 25%;
    }
  }
  @media only screen and (min-width:201px) and (max-width:600px){
    .form-containers{
      width: 90%;
      position: initial;
      left: 0%;
    }
  }