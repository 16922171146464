.EmployeeHome_main{
    width: 45%;
    top:5%;
    left: 25%;
    height: 90%;
    position: fixed;
    display: flex;
    flex-direction: column;
    background: #E5E5E5;
    border-radius: 13px;
    font-family: "Poppins",sans-serif;
}
.employeehomepage{
    width: 100%;
    height: 100%;

    /* height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 13px;
    /* margin: auto; */
    overflow-y: scroll;
    background: #ffff;
    font-family: "Poppins", sans-serif;
}
.employee_header{
    width: 100%;
    height: 28.4vh;
    /* height: auto; */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 3rem;
    font-family: "Poppins", sans-serif;
  
    background: rgba(140,221,220,0.5);
}
.headeremployee{
    line-height: 30px;
    font-weight: bold;
}
.employee_header_left{
    width: 60%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */
}
.employee_home_header_buttons{
    display: flex;
    flex-direction: row;
    gap: 2rem;
}
.employee_home_header_buttons .hire{
    padding: .4rem;
    width: 24%;
    border: none;
    background: rgba(247,112,29,0.39);
    border-radius: 11px;
    text-decoration: none;
    color:black;
    font-size: 13px;
    text-align: center;
}
.hire a{
    text-decoration: none;
}
.employee_header_right{
    width: 40%;
    /* border: 2px solid red; */
}
.employee_header_right .employeehome_header_img{
    margin-top: -1.5rem;
    
    width: 50%;
    
}
.employer_home_landing{
    width: 80%;
    /* height: 60vh; */
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 2rem;
}
.employee_profile{
    width: 90%;
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    align-items: center;
}
.employee_profile img{
    display: flex;
    padding: 1rem;
width: 20%;
/* width: 60%;
height: 60%; */
}
.audioplusmail{
    margin-top: 1rem;

}
.audio_ad{
    width: 100%;
    border: 2px solid red;

}

.employee_home_form{
    width: 100%;
display: flex;
flex-direction: column;
}
.employee_home_form form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}
/* .employee_home_form form input{
    width: 100%;
    padding: .9rem;
    border: none;
    outline: none;
    border-bottom: 3px solid #F7701d;
}
.employee_home_form textarea{
    width: 100%;
    height: 8vh;
    border: none;
    outline: none;
    border-bottom: 3px solid #F7701d;
} */

.freelance_btns{
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.freelance_payment_btns{
    width: 100%;
    display: flex;
    justify-content: space-between;
  
}
.freelance_btn{
    width: 100%;
    display: flex;
    justify-content: center;
}
.profile_btn{
    padding: .5rem;
    border: 3px solid #F7701d;

}
@media only screen and (max-width:950px) {
    .employee_profile{
        flex-direction: column;
    }
    .employee_header{
        flex-direction: column;
        padding: 1rem;
        height: auto;
    }
    .employee_header_left{
        width: 100%;
        justify-content: center;
        text-align: center;
    }
    .employee_header_right{
        width: 100%;
        display: flex;
        justify-content:center;
    }
    .audiooo{
        width: 190px;
        padding: .5rem;
        }
        .employee_profile img{
            width: 50%;
        }
        .freelance_payment_btns{
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .profile_btn{
            width: 90%;
        }
}