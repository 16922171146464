.Job_not_posted{
    display: flex;
    flex-direction: row;
}
.Job_NotPosted_content{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-position:right bottom ;
    background-repeat: no-repeat;
    /* background-image: url("../../../public/employer/background.jpeg"); */

}
.JobNotPosted{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 77vh;
    overflow-y: scroll;
}
.jobnotposted{
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    gap: 2rem;
}
.NoJob-img img{
    width: 60%;
    margin-top: 1rem;
}
.NoJob-Heading {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.NoJob-Heading  h2{
    width: 100%;
}
.NoJob-btn {
    /* width: 450px; */
    width:100%;
}
.pstjob{
    width: 23%;
    padding: 1rem;
    border-radius: 2rem;
    background-color: rgba(247,112,29,0.39);
    cursor: pointer;
    border-radius: 50px;
    border: none;
    font-size: larger;
    font-weight: 500;

}

@media only screen and (min-width:201px) and (max-width:600px){
    .JobNotPosted{
        position: fixed;
        top:10%;
        bottom: 13%;
        height: auto;
    }
    .Job_not_posted{
        display: flex;
        flex-direction: column-reverse;
    }
    .NoJob-btn{
        width: 100%;
    }
    .pstjob{
        width: 53%;
        padding: 1rem;
        border-radius: 2rem;
        background-color: rgba(247,112,29,0.39);
        cursor: pointer;
        border-radius: 50px;
        border: none;
        font-size: larger;
        font-weight: 500;
    
    }}
 