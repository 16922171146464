.hire_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.hirecard_container{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(247,112,29,0.39);
    border-radius: 20px;
    /* padding: .5rem; */
    margin-top: 1rem;
    padding:  2.5rem;
         /* border: 2px solid black; */

     
    
}
.hire_left{
     display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 5px solid green; */
    /* margin: -3.5rem 0rem 0rem -2rem; */
    padding: 0rem 0rem 0rem 2rem;
    width: 70%;

     /* border: 3px solid green; */
    /* justify-content: center;
    align-items: center;  */
}
.hire_left h4{
    /* font-size: 1.8rem; */
    line-height: 35px;
    font-weight: 600;
    font-size: 22px;
    margin: 0.7rem 0rem 0rem 0rem ;

}
.hire_left h5{
    font-weight: 500;
    margin-top: 1rem;
    font-family: "Poppins",sans-serif;
    line-height: 29px;
    font-style: normal;
    font-size: 18px;
 
}
.hire_left .hirehome{
    width: 30%;
    border: none;
    color:#ffff;
    background: #F7701D;
    border-radius: .3rem;
    padding: .5rem;
    margin-top: 1rem;
}
.hire_right{
    padding: .7rem;
    /* width: 100%;
    height: 100%; */
    width: 35%;
    display: flex;
    justify-content: center;
    /* border: 2px solid black; */
}
.hire_right img{
    width: 40%;
    /* border: 2px solid black; */
}
