.Blogs-Container-BlogSection{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 4rem;
}
.Blogs-Section-Image{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.Blog_Title{
    /* margin-left: 5%; */
    text-align: left;
}
.blog_continue_img{
    width: 80%;
}
.blog_continue{
    font-family: 'Volkhov';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 23px;
display: flex;
/* align-items: center; */
text-transform: capitalize;

color: #000000;

}
.Blogs-Section-Content{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Auth-Name{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    margin-top: 2rem;
    margin-left: 2rem;
}
.Auth-Photo{
    width: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.Auth-Name-Name{
    width: 20%;
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.8rem;
        /* border: 2px solid black; */

    font-family: 'Poppins','Volkhov', serif;
}
.Auth-Name-Name h2{
    margin: 0%;
}
.Auth-Name-Name h4{
    margin: 0%;
}
.Auth-Name-Name h5{
    margin: 0%;
}
.Blogs-Section-Content-content{
    width: 95%;
}
.Blogs-Content-Buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.content-btn-1{
   background-color: rgba(255, 255, 255, 0);
   border: none;
   color:  #DF6951;
   font-size: 2.2rem;
   cursor: pointer;
}
.content-btn-2{
    background-color: rgba(255, 255, 255, 0);
    border: none;
    color:  #DF6951;
    font-size: 2rem;
    cursor: pointer;
}
.Comments-Section{
    width: 70%;
    /* height: 70vh;
    overflow-y: scroll; */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
    padding: 1rem;
    margin: auto;
    /* border: 2px solid red; */
}
.PostComment{
    width: 60%;
    display: flex;
    flex-direction: column;
    /* border: 2px solid red; */
    align-items: center;
    gap: 2rem;
    margin-left: 4rem;
}
.PostComment textarea {
    width: 100%;
 padding: 2rem;
 font-weight: 600;
 border: 2px solid #F7701D;
 border-radius: 10px;
 outline: none;
}
.PostComment-Button{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.Post-Btn{
    width: 22%;
    padding: 0.6rem;
    font-size: 1.3rem;
    border: none;
    border-radius: 10px;
    background-color: #F7701D63;
}
.progressbar_blog{
    width:70%;
    display: flex;
    flex-direction: row;
margin: auto;
    justify-content: center;
    align-items: center;
}
.head_container{
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 1rem;
}
.category_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 2.5rem 0rem 3rem 0rem;
    /* background-image: url("../../screens/jobseeker/Blogs/blogs01.svg"); */
    /* background-image: ""; */
}
.categories_blogs{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}