.Save_Job_Page{
    display: flex;
    flex-direction: row;
    /* background-image: url("../assets/Navbarbackground.jpeg"); */
    background-position: bottom right ;
    background-repeat: no-repeat;
}
.Saved_Job_content{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.Saved-Job-Cards-Section{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: auto;
    height: 80vh;
    overflow-y: scroll;
    
}
.JOB-Saved-Card{
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* border: 5px solid red; */
    justify-content: space-between;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
  
}
.JOB-Saved{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center; */
    /* padding-left: 1rem; */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    gap: 1rem;
    background: #FFFFFF;
    /* border: 3px solid red; */
    /* padding-top: 1rem;
    padding-bottom: 1rem; */
    /* padding: 2rem; */
    padding: 2rem 2rem 0.5rem 2rem;
}
.Saved-JOB-Button{
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.Saved_job_btn{
    width: 85%;
    font-weight: 500;
    padding: 8px;
    border-radius: 5px;
    text-align: center;
    /* display: flex; */
    /* flex-direction: row;
    align-items: center; */
    justify-content: center;
    background:#AFE7E7;
    border: none;
    margin: 1rem 0rem 0.7rem 0rem ;
    height: 30px;
    /* border: 2px solid #AFE7E7; */
    cursor: pointer;
    font-size: 15px;
    /* padding: .2rem; */

}
.JOB-Saved-Comp{
    width: 100%;
    /* border: 3px solid yellow; */
    margin: 0%;
}
.JOB-Saved-Comp h3{
    margin-top: 0%;
    /* margin: 0%; */
    /* margin: 0rem ; */
    font-weight: 600;
    /* line-height: 30px; */
    /* width: 100%; */
}
.JOB-Saved-Comp h4{
    /* margin: 0%; */
    margin: 0%;
    line-height: 32px;
    /* font-size: 15px; */
    font-family: "Poppins";
    font-weight: 600;
    /* margin: 6%; */
    color: #898787;
}
.saved_bookmark_img{
    width: 60%;
    display: flex;
    justify-content: center;
}
.savedlogoclass{
transform: scale(.5);
}
@media only screen and (min-width:601px) and (max-width:900px){
    .Saved-Job-Cards-Section {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* border: 5px solid green; */
        margin-top: 17px;
        margin-left: 0rem;
        height: 60vh;
        overflow-y: scroll;
    }
    .JOB-Saved-Card {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* border: 5px solid red; */
        justify-content: space-between;
        gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 1rem;
    }
    .JOB-Saved {
        width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* align-items: center; */
        /* padding-left: 1rem; */
        box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
        border-radius: 10px;
        gap: 1rem;
        background: #FFFFFF;
        /* border: 3px solid red; */
        /* padding: 2rem; */
        padding: 2rem 2rem 0.5rem 2rem;
    }
}

@media only screen and (min-width:201px) and (max-width:600px){
    .Save_Job_Page{
        display: flex;
        flex-direction: column-reverse;
        /* background-image: url("../assets/Navbarbackground.jpeg"); */
        background-position: bottom right ;
        background-repeat: no-repeat;
    }
    .Saved-Job-Cards-Section {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 15vh;
        bottom: 6vh;
        /* border: 5px solid green; */
        margin-top: 17px;
        margin-left: 0rem;
        height: auto;
        overflow-y: scroll;
    }
    .JOB-Saved-Card {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        /* border: 5px solid red; */
        justify-content: space-between;
        gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 1rem;
    }
    .JOB-Saved {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        /* align-items: center; */
        /* padding-left: 1rem; */
        box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
        border-radius: 10px;
        gap: 1rem;
        background: #FFFFFF;
        /* border: 3px solid yellow; */
        padding: 1rem;
        /* padding: 2rem 2rem 0.5rem 2rem; */
    }
    .JOB-Saved-Comp h4{
        /* margin: 0%; */
        margin: 0%;
        line-height: 26px;
        /* font-size: 15px; */
        font-family: "Poppins";
        font-weight: 600;
        /* margin: 6%; */
        color: #898787;
    }
    
    

}