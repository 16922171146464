.form_row{
    width: 80%;
    display: flex;
    flex-direction: column;

}
.p2_main {
    width: 100%;
    height: 40vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.radioRS {
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.labeled_textsalary{
    font-size: 15px;
    color: #898787; 
  
  }