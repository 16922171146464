.forgotpassword_container{
    position: absolute;
    top: 3rem;
    left: 23rem;
    width: 50%;
    /* border: 2px solid red; */
  
    height: 80%;
    background: #fff;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 45px rgba(0, 0, 0, 0.25);
    transition: 0.5s ease-in-out;
    overflow: hidden;
    flex-direction: column;
    gap: 1rem;
    border-radius: 7px;
  
}
.forgot_input{
    width: 45%;
    /* border: 2px solid green; */
}
.forgotheading{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}