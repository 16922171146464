.employeeSubscription_plan_container {
    width: 85%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    /* border: 2px solid red; */
}

.employeesubscription_card {
    width: 95%;
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.h3-title{
    /* border: 2px solid black; */
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.5rem;
    font-weight: 600;
}

.ESC_plans{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* border: 5px solid green; */
}
.checkbox1{
    width: 10%;
    height: 30%;
    /* border: 1px solid red; */
    padding: 0rem 0rem 0rem 0rem ;
    display: flex;
    flex-direction: row;
    justify-content: center;
     /* border: 1px solid blue; */
     margin-top: 1.4rem;
    

}
.checkbox1 input[type=checkbox]{
    -webkit-transform: scale(3);
    /* border: 5px solid rgba(247, 112, 29, 0.39);
    background: rgba(247, 112, 29, 0.39); */
}
.subscription_content{
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    width: 90%;
    /* border: 1px solid blue; */
    gap: 0rem;
    margin: 1rem 0;
}
.subscription_content h2{
    

    /* font-style: normal; */
    /* border: 1px solid blue; */
    margin-top: 0;
    font-size: 1.6rem;
    margin-bottom: 1rem;
}
.subscription_content p{
    /* line-height: 0px; */
    line-height: 29px;
    /* font-weight: 600; */
    /* font-family: "Poppins"; */
    /* font-style: normal; */
    /* border: 1px solid blue; */
}
.line_braker{
    width: 100%;
    height: 6px;
    background: rgba(247, 112, 29, 0.39);
}

@media only screen and (max-width:768px) {
    .employeesubscription_card{
        width: 100%;
    }

    .h3-title{
        font-size: 1.2rem;
    }
    .subscription_content h2{
        font-size: 1.3rem;
    }
    .subscription_content p{
        font-size: .9rem;
    }
    
    .checkbox1{
       padding: .6rem 0rem 0rem 0rem;
       margin-top: .7rem;
    }

    .checkbox1 input[type=checkbox]{
        -webkit-transform: scale(2.5);
       
    }
    
    
    
}

@media only screen and (max-width:500px) {

    .h3-title{
        font-size: 1rem;
    }
    .subscription_content h2{
        font-size: 1.1rem;
    }
    .subscription_content p{
        font-size: .8rem;
    }
    
    .checkbox1{
       padding: .4rem .6rem 0rem 0rem;
       margin-right: .5rem;
       margin-top: .9rem;
       
       /* border: 2px solid black; */
    }

    .checkbox1 input[type=checkbox]{
        -webkit-transform: scale(2);
       
    }
    .employeesubscription_card{
        padding: 1rem;
    }
    
    
    
}