.addtempmain_container{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:0%;
    left:0rem;
    z-index: 999;
    margin: auto;
    background: rgba(32,32,32,.8);

}
.addtemplate_containerr{
    width: 50%;
    height: 70vh;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(31, 30, 30, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    /* padding: 1rem; */
    background-image: url("../../../public/employer/background.jpeg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 55vh;

}
.addtemplate_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    /* width: 50%;
    height: 80vh;
    margin: auto;
    display: flex;
    background-image: url("../../../public/employer/background.jpeg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 55vh;
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border-radius: 5px;
    margin-top: 4rem;
    box-shadow: 0px 4px 50px rgba(0,0,0,0.25);
 */
    /* top:0%;
    left:0rem;
    z-index: 999;
    margin: auto;
    background: rgba(32,32,32,.8); */

}
.T_main{
    width: 90%;
    height: 55vh;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 2rem;

}
/* .temp_input{
    border: 2px solid #f7701d;
    border-radius: 5px;
} */
.temp_input_text{
    border-radius: 5px;
}
::placeholder{
    color: #878989;
    font-style: "Poppins";
    font-size: 14px;
    text-indent: 9px;
}
.template_btn{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black; */

}
@media only screen and (min-width:200px ) and (max-width:400px) {
.addtemplate_containerr{
    width: 90%;
}
}
@media only screen and (min-width:400px ) and (max-width:600px) {
    .addtemplate_containerr{
        width: 90%;
        height: 65vh;
    }
    
}

@media only screen and (min-width:600px ) and (max-width:1400px) {
    .addtemplate_containerr{
        width: 80%;
        height: 57vh;
    }

}