.plan-container1 {
  width: 95%;
  display: flex;
  flex-direction: row;
  margin: 2rem auto;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: auto;
  background-color: white;

}

.plan-child {
  width: 33.33%;
  height: 85vh;
  /* border: 2px solid red; */
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  float: left;
  margin: auto;
}

.card-container-plan {
  float: left;
  position: relative;
  /* border: 2px solid yellow; */
  /* margin: auto; */
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.subscriptioncard1 {
  position: absolute;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: all 1s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 2px solid blue; */
}

.frontcard_content {
  position: absolute;
  width: 100%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  /* margin: auto; */
  align-items: center;
  backface-visibility: hidden;
  background: linear-gradient(180deg, #ffff 20%, #c4e7ed 80%);
  border-radius: 15px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  gap: 3rem;
  padding: 0.5rem;


}

.subscriptioncard1:hover {
  transform: rotateY(180deg);
}

.inputfront {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inputfront img {
  margin-left: 7rem;
  width: 28%;
  padding: 1.2rem 1.2rem 0rem 1.2rem;
}

.basicplan_btns {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
}

.info {
  width: 100%;
  height: auto;
  padding: 4%;
  position: absolute;
  background: linear-gradient(180deg, #ffff 20%, #c4e7ed 80%);
  border-radius: 15px;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  backface-visibility: hidden;
  transform: rotatey(180deg);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 10px;
  /* border: 2px solid green; */
}

.backcontents {
  width: 90%;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;
  /* border: 2px solid yellow; */

}
.backcontent_para{
  /* border: 1px solid yellowgreen; */
  margin: 0%;
}

.backcontents .uls_js {
  /* border: 1px solid black; */
  justify-content: flex-start;
  /* text-align: justify; */
}

.front_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn_buynowF {
  width: 30%;
  border: none;
  background: rgba(247, 112, 29, 0.39);
  border-radius: 10px;
  padding: 0.3rem;
}

.back_btn {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.btn_buynowF {
  width: 40%;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  border: none;
  margin-top: 3rem;
  background: rgba(247, 112, 29, 0.39);
  border-radius: 7px;
  padding: 0.3rem;
  height: 40px;
}

.btn_buynowB {
  width: 40%;
  font-family: "Poppins";
  font-size: 17px;
  font-weight: 600;
  border: none;
  margin-top: 3rem;
  background: rgba(247, 112, 29, 0.39);
  border-radius: 7px;
  padding: 0.3rem;
  height: 40px;
}

@media only screen and (max-width:920px) {
  .plan-container1 {
    flex-direction: column;
    margin-left: 2.2rem;
  }

  .plan-child {
    width: 93%;
  }
}

@media only screen and (max-width:500px) {
  .plan-container1 {
    flex-direction: column;
    margin-left: 1.2rem;
  }

  .plan-child {
    width: 93%;
  }

}