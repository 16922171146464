.Notification_content{
    width: auto;
    height: auto;
    position: fixed;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    top:15.5%;
    right: 0.5rem;
    /* border: 3px solid yellow; */
    /* border: 5px solid blue; */
    /* left:25rem; */
    z-index: 900;
    /* margin: auto; */
    margin: 0rem 0rem 0rem 0rem;
    /* background: rgba(32,32,32,.8); */
}
.NotificationDropDown{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 330px;
    height: 30vh;
    /* overflow-y: scroll; */
    border-radius: 15px;
    /* border: 3px solid red; */
    /* margin-top: 29rem; */
}
.DropDownNoti{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* align-items: flex-start;
    justify-content: flex-start; */

    width: 100%;
    height: 100vh;
    background-color: gray;
    border-radius: 10px;
    background: #F2F2F2;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
    position: relative;
    /* left: -5rem; */
}
.notification_heading{
    display: flex;
    font-family: "Poppins",sans-serif;
    flex-direction: row;
    width: 100%;
    /* border:2px solid red; */
    justify-content: space-around;
}
.notification_heading button{
    border: none;
    background:none;
    outline: none;
    cursor: pointer;
}
.notification_heading button:hover{
    color: #F7701d;

}
.v1-N{
    border: 1px solid #F7701D;
    width: 100%;
}
.DropDownNoti p{
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #464353;
    cursor: pointer;
}
.notification_btn_grp{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;

    
  }
  .notification_btn_grp button{
    border-radius: 15%;

  }

@media only screen and (min-width:201px) and (max-width:700px){
    .Notification_content{
        width: auto;
        height: auto;
        position: fixed;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        top:11%;
        right: 0.5rem;
        /* border: 3px solid yellow; */
        /* border: 5px solid blue; */
        /* left:25rem; */
    
        z-index: 900;
        /* margin: auto; */
        margin: 0rem 0rem 0rem 0rem;
        /* background: rgba(32,32,32,.8); */
    }

}