.notification_page{
    width: 95%;
    margin: auto;
    height: 80vh;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.notify_card{
    width: 95%;
}
.itemEmp {
    background: #fff;
    margin-bottom: 0.7rem;
    margin-top: 0.7rem;
    border-radius: 15px;
    box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
    padding: 1.2rem;
}

.titleEmp {
    display: flex;
    cursor: pointer;
}
.questionnoEmp {
    margin: 0rem 2rem 0rem 2rem;
    color: #F7701D;
    font-size: 20px;

}