.usefilter2_container{
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 2rem;
}
.usefilter2_form{
    width: 100%;
}
.usefilter2_form form{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.collg_uni_LI{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: .8rem;
}
.collg_uni_LI select{
    padding: .8rem;
    border: 2px solid #F7701D;
    border-radius: 10px;
}
.collg_uni_LI label{
    margin-left: .5rem;
    color: #898787;
}
.form-row2{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: .8rem;
    gap: .7rem;
}
/* @media only  screen and (max-width:600px){
    .usefilter2_form form{
        width: 100%;
        gap: 2rem;
        border: 2px solid red;
    }
    
} */
@media only screen and (min-width: 200px) and (max-width: 400px) {
    .usefilter2_form form{
        gap:1.2rem;
    
    }    
    
    }
    
@media only screen and (min-width: 401px) and (max-width: 600px) {
    .usefilter2_form form{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.3rem;
        /* border: 2px solid red; */
    
    }


}
@media only screen and (min-width: 601px) and (max-width: 800px) {
    .usefilter2_form form{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.3rem;
        /* border: 2px solid red; */
    
    }


}
@media only screen and (min-width: 801px) and (max-width: 1000px) {
    .usefilter2_form form{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 2.3rem;
        /* border: 2px solid red; */
    
    }


}
@media only screen and (min-width: 1001px) and (max-width: 1200px) {
    .usefilter2_form form{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.3rem;
        /* border: 2px solid red; */
    
    }


}

