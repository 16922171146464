.share_pp_container{
    width: 90%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:0%;
    left:4rem;
    z-index: 999;
    margin: auto;
    background: rgba(32,32,32,.8);
}
  .titleCloseBtn{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* border: 3px solid blue; */
    /* align-items: flex-end; */
    
}
.titleCloseBtn button{
    background: transparent;
    border: 10px;
    font-size: 20px;
    margin: 0.5rem 1rem 0rem 0.5rem;
    cursor: pointer;

}

.share_container{
    width: 50%;
    /* position: relative; */
    height: 50%;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(31, 30, 30, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* z-index: 2; */

}
.s_content{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
}
.s_heading{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}
.socialmedia_Icons{
    width: 100%;
    /* border: 2px solid blue; */
    display: flex;
    flex-direction: row;
    justify-content:center;
    gap: 5rem;
}
.Social-Media{
    border: none;
    background:#fff;
    cursor: pointer;
}

.share-btnn{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.share_btnn{
    width: 23%;
    border: none;
    background: rgba(247,112,29,.39);
    border-radius: 10px;
    padding: .6rem;
    margin-top: 1.9rem;
    font-size: 18px;
    font-weight: 500;
}
.Icons_all{
    transform: scale(3.8);
}
.facebook{
    transform: scale(3.4);
}
.telegram{
    transform: scale(3.4);
}
.instagram{
    transform: scale(3.0);
}
