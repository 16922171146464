.card_head {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  color: #464353;
}

.ssmain {
  position: absolute;
  top: 31%;
  left: 38%;
  transform: translate(-50%, -50%);
  width: 23%;
  outline: none;
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
  background: #F2F2F2;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;

}

@media only screen and (max-width:850px) {
  .ssmain {
    width: 35%;
    text-align: center;
  }
}

@media only screen and (max-width:500px) {
  .ssmain {
    width: 73%;
    text-align: center;
  }
}