.share_pp_container{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:0%;
    left:0rem;
    z-index: 999;
    margin: auto;
    background: rgba(32,32,32,.8);
}
  .titleCloseBtn {
    display: flex;
    justify-content: flex-end;
  }
  /* .titleCloseBtn button{
    font-size: 1.7rem;
    border-radius: 7px;
    color: #000000;

  } */
.share_container{
    width: 80%;
    height: 40%;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(31, 30, 30, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* z-index: 2; */

}
.s_content{
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
        /* border: 2px solid red; */

}
.s_heading{
    width: 90%;
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
 /* margin-top:-3rem; */
margin-left: 8rem; 
    justify-content: flex-start;
    align-items: flex-start;
}
.socialmedia_Icons{
    width: 90%;
    /* border: 2px solid blue; */
    padding: 1rem;
    display: flex;
    margin-left: 8rem; 

    flex-direction: row;
    justify-content:flex-start;
    align-items: flex-start;
    gap: 5rem;
}
.Social-Media{
    border: none;
    background:#fff;
    cursor: pointer;
}

.share-btnn{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.share_btnn{
    width: 23%;
    border: none;
    background: rgba(247,112,29,.39);
    border-radius: 10px;
    padding: .6rem;
    margin-top: 1.9rem;
    font-size: 18px;
    font-weight: 500;
}
.Icons_all{
    transform: scale(3.8);
}
.facebook{
    transform: scale(3.4);
}
.telegram{
    transform: scale(3.4);
}
.instagram{
    transform: scale(3.0);
}
@media only screen and  (max-width:1284px){
    .share_container{
        width: 90%;
        /* position: relative; */
        height: 50%;
        /* padding: 1rem; */
        /* z-index: 2; */
        /* border: 2px solid red; */
    
    }
    .socialmedia_Icons{
        width: 100%;
        /* border: 2px solid blue; */
        padding: 1rem;
        display: flex;
        margin-left: 0;
        margin-left: 8rem; 
    /* margin-right: 3rem; */
        flex-direction: row;
        justify-content:flex-start;
        align-items: flex-start;
        gap: 4.3rem;
    }
    .s_heading{
        justify-content: center;
        margin-right: 8rem;
    }
    
  
  
}
@media only screen and (min-width:1024px) and (max-width:1100px){
    .share_container{
        width: 90%;
        /* position: relative; */
        height: 50%;
        /* padding: 1rem; */
        /* z-index: 2; */
        /* border: 2px solid red; */
    
    }
    .socialmedia_Icons{
        width: 100%;
        /* border: 2px solid blue; */
        padding: 1rem;
        display: flex;
        margin-left: 0;
        /* margin-left: 8rem;  */
    margin-right: 3rem;
        flex-direction: row;
        justify-content:flex-start;
        align-items: flex-start;
        gap: 4.3rem;
    }
    .s_heading{
        justify-content: center;
        margin-right: 8rem;
    }
    
  
}

@media only screen and (min-width:901px) and (max-width:1024px){
    .share_container{
        width: 90%;
        /* position: relative; */
        height: 40%;
        /* padding: 1rem; */
        /* z-index: 2; */
        /* border: 2px solid red; */
    
    }
    .s_content{
        height: 60%;
        gap: 3.5rem;
    }

    .socialmedia_Icons{
        width: 100%;
        /* border: 2px solid blue; */
        padding: 1rem;
        display: flex;
        /* margin-left: 8rem;  */
    margin-right: 4rem;
        flex-direction: row;
        justify-content:flex-start;
        align-items: flex-start;
        gap: 2.8rem;
    }
    .s_heading{
        justify-content: center;
        margin-right: 8rem;
    }
    

}
@media only screen and (min-width:801px) and (max-width:900px){
    .share_container{
        width: 90%;
        /* position: relative; */
        height: 40%;
        /* padding: 1rem; */
        /* z-index: 2; */
        /* border: 2px solid red; */
    
    }
    .s_content{
        height: 60%;
        gap: 3.5rem;
    }
    .socialmedia_Icons{
        width: 100%;
        /* border: 2px solid blue; */
        padding: 1rem;
        display: flex;
        /* margin-left: 8rem;  */
    margin-right: 5rem;
        flex-direction: row;
        justify-content:flex-start;
        align-items: flex-start;
        gap: 2rem;
    }
    .s_heading{
        justify-content: center;
        margin-right: 8rem;
    }
    

}
@media only screen and (min-width:701px) and (max-width:800px){
    .share_container{
        width: 90%;
        /* position: relative; */
        height: 40%;
        /* padding: 1rem; */
        /* z-index: 2; */
        /* border: 2px solid red; */
    
    }
    .s_content{
        height: 60%;
        gap: 3.5rem;
    }

    .socialmedia_Icons{
        width: 100%;
        /* border: 2px solid blue; */
        padding: 1rem;
        display: flex;
        /* margin-left: 8rem;  */
    margin-right: 6.5rem;
        flex-direction: row;
        justify-content:flex-start;
        align-items: flex-start;
        gap: 1.5rem;
    }
    .s_heading{
        justify-content: center;
        margin-right: 8rem;
    }
    
   
}
@media only screen and (min-width:601px) and (max-width:700px){
    .share_container{
        width: 90%;
        /* position: relative; */
        height: 50%;
        /* padding: 1rem; */
        /* z-index: 2; */
        /* border: 2px solid red; */
    
    }
    .socialmedia_Icons{
        width: 100%;
        /* border: 2px solid blue; */
        padding: 1rem;
        display: flex;
        /* margin-left: 8rem;  */
    margin-right: 8rem;
        flex-direction: row;
        justify-content:flex-start;
        align-items: flex-start;
        gap: .5rem;
    }
    .s_heading{
        justify-content: center;
        margin-right: 8rem;
    }
    
  }
  /* @media only screen and (min-width:400px) and (max-width:600px){
  
  
  }
  @media only screen and (min-width:301px) and (max-width:401px){
  
  } */
  
  @media only screen and (min-width:201px) and (max-width:601px){
    .share_container{
        width: 85%;
        /* position: relative; */
        height: 60%;
        /* padding: 1rem; */
        /* z-index: 2; */
        /* border: 2px solid red; */
    
    }
    .socialmedia_Icons{
        width: 100%;
        padding: 1rem;
        display: grid;
        grid-template-columns: 50% 50%;
        justify-content: center;
        align-items: center;
        margin-left: 0;
        /* border: 2px solid red; */
        /* display: flex;
    margin-right: 8rem;
        flex-direction: column;
        justify-content:center;
        align-items:center; */
        gap: .5rem;
    }
    .s_heading{
        justify-content: center;
        margin-right: 8rem;
        
    }
    .s_heading h1{
        font-size:2rem;
    }
    

  }