.Blogs-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: "Poppins", sans-serif;
}
.navbar_blog{
    width: 100%;
}
.topheaderblog {
    width: 100%;
    background-image: linear-gradient(white, #e0f6f6);

}

.BLogs-Header {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
    font-size: 25px;
    align-items: center;
    margin-top: 2rem;
    /* border: 2px solid red; */
    justify-content: center;
}

.BLogs-Header h2 {
    margin: 0;
    font-size: 2.5rem;
}

.text_description {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    margin: 0.2rem 0rem 0.7rem 0rem;

    color: #000000;

}

.Blogs-Image {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog_img{
    width: 80%; 
    border-radius: 15px;
}



.Blogs-Carousel {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
    /* border: 2px solid red; */
}

.Blogs-Carousel-Track {
    width: 100%;
    display: grid;
    grid-template-columns: 40% 40%;
    grid-row-gap: 1rem;
    align-items: center;
    justify-content: center;
    gap: 5rem;
    /* padding-right: 2rem; */
    padding-top: 3rem;
    padding-bottom: 3rem;

}

.Blogs-Card {
    width: 100%;
    height: 100%;

    /* width: 20%; */
    display: flex;
    float: left;
    flex-direction: column;
    align-items: center;
}

.Blogs-Card-Image {
    width: 100%;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}


.Blogs-Card-Content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.blog_title {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    margin: 0.5rem 0rem 0.5rem 0rem;
    color: #000000;
}
.blogdate {
    font-family: 'Poppins',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 37px;
    margin: 0.2rem 0rem 0rem 0rem;
    color: rgba(0, 0, 0, 0.5);
}

.blog_btn {
    width: 25%;
    display: flex;
    padding: .5rem;
    justify-content: flex-start;
    align-items: center;
    /* margin-left: 18rem; */
    border: none;
    text-align: center;
    height:40px;
    cursor: pointer;
    border-radius: 8px;
    /* border: 2px solid red; */
    background-color: #8CDDDC;
    color: #FFFFFF;
}

.Blogs-Card-Content a {
    text-decoration: none;
}

.paginationBttns {
    width: 100%;
    height: 40px;
    list-style: none;
    display: flex;
    align-items: center;
    /* justify-content: flex-start; */
    justify-content: flex-start;
    /* margin-left: 28rem; */
}

.paginationBttns a {
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    /* border: 1px solid #2b2eff; */
    color: #000;
    background-color: #8CDDDC;
    cursor: pointer;
}

.paginationActive a {
    color: white;
    background-color: #898787;
}

.paginationDisabled a {
    color: grey;
}

/* @media only  screen and (max-width:1200px)
  {
 .BLogs-Header h2{
    font-size: 1.4rem; 
    width: 20%;
    margin: 0;
    margin-left: 1.5rem;
 }
 .line img{
 width: 90%;
 }
  }
  */
@media only screen and (max-width:900px) {
    .BLogs-Header h2 {
        font-size: 1.4rem;
        width: 20%;
        margin: 0;
        /* margin-left: 1.5rem; */
    }

    .line img {
        width: 90%;
    }
}

@media only screen and (max-width:650px) {
    .BLogs-Header h2 {
        font-size: 1.2rem;
        /* width: 50%; */
    }

    .line img {
        width: 90%;
    }

    .paginationBttns {
        width: 80%;
    }

    .paginationBttns a {
        margin: 3px;
        padding: 5px;
    }

    .Blogs-Card-Content h1 {
        margin: 0%;
        font-size: .9rem;
        font-weight: 500;
        font-family: "poppins", sans-serif;
    }

    .blog_btn {
        width: 75%;
        display: flex;
        padding: .17rem;
        justify-content: center;
        /* margin-left: 18rem; */
        border: none;
        text-align: center;
        border-radius: 5px;
        /* border: 2px solid red; */
        background-color: #8CDDDC;
        color: #FFFFFF;
        font-size: .8rem;
    }

    .Blogs-Carousel-Track {
        /* width: 85%;
            height: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 2rem;
            border: 2px solid black;
        
            margin-bottom: 2rem; */
        width: 100%;
        display: grid;
        grid-template-columns: 40% 40%;
        grid-row-gap: 1rem;
        align-items: center;
        justify-content: center;
        gap: 2.0rem;
        /* padding-right: 2rem; */
        padding-top: 3rem;
        padding-bottom: 3rem;

    }



}