.faqEmp_container {
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0%;
    left: 0rem;
    z-index: 999;
    margin: auto;
    background: rgba(32, 32, 32, 0.7);
  }
  .faqEmp_board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .questionnoEmp{
    color: #F7701D;
    font-size: 15px;
    margin: 0rem 2rem 0rem 2rem;
  
  }
  .faqEmpCloseBtn{
      width: 50%;
      display: flex;
      justify-content: flex-end;
  }
  .faqEmpCloseBtn button{
      margin: 0rem 1.5rem -2.3rem 0rem;
      font-size: 1.7rem;
      border-radius: 7px;
      color: #000000;
      cursor: pointer;
      background: transparent;
      border: none;
      font-weight: 600;
      z-index: 3;
  }
  .faqEmp_Container {
    width: 50%;
    height: 95%;
    background-image: url("../../../public/jobseeker/background.jpeg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 50vh;
    background-color: white;
    display: flex;
    overflow-y: scroll;
    border-radius: 10px;
    flex-direction: column;
    font-family: "Poppins",sans-serif;
  }
  .faqEmp_container_content {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .faqEmpup_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem;
  }
  
  .faqEmpup_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
  .faqEmpup_right {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  .faqEmpup_right img {
    width: 100%;
    height: 100%;
  }
  .faqEmpup_left h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .search_container {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* box-sizing: border-box; */
    margin: 2rem 0rem 0rem 2rem;
  }
  .search_container input {
    width: 100%;
    border-radius: 15px;
    border: 3px solid #51557e;
    padding: 0.8rem;
  }
  .accordionEmp {
    width: 90%;
    margin: auto;
  }
  .itemEmp {
    background: #fff;
    margin-bottom: 0.6rem;
    margin-top: 1rem;
    border-radius: 15px;
    box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
    padding: 1.2rem;
  }
  
  .title_headingEmp{
    margin: 0;
    font-size: 0.8rem;
    line-height: 1.3rem;
    font-family: "Poppins",sans-serif;
  
  }
  .titleEmp {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    cursor: pointer;
  }
  .contentEmp {
    max-height: 0;
    overflow: hidden;
  }
  .contentEmp.showEmp {
    height: auto;
    max-height: 9999px;
    border-radius: 8px;
    background-color: rgba(247, 112, 29, 0.39);
    padding: 0.8rem;
    margin-top: 1rem;
  }


  @media only screen and (min-width:200px ) and (max-width:300px) {
    .faqEmp_Container {
      width: 72%;
      height: 72%;
      /* margin-left: -5rem; */
    }
    .itemEmp{
      padding: .3rem;
      text-align: justify;
      font-size: 11px;
    
    }
    .questionnoEmp{
  margin:0rem .5rem 0rem .5rem;
  
    }
    .title_headingEmp{
      font-size: 11px;
    }
    .faqEmpCloseBtn{
      width: 70%;
    }
    .faqEmpup_container{
      /* border: 2px solid red; */
  flex-direction: column;
    }
    .faqEmpup_left{
      width: 100%;
      text-align: justify;
      /* border: 2px solid red; */
  
    }
    .faqEmpup_left h1{
      font-size: 13px;
      font-weight: 600;
      margin: 0;
    }
    .faqEmpup_right img{
      display: none;
    }
  
  }
    @media only screen and (min-width:300px ) and (max-width:600px) {
    .faqEmp_Container {
      width: 72%;
      height: 72%;
      /* margin-left: -5rem; */
    }
    .itemEmp{
      padding: .5rem;
      text-align: justify;
      font-size: 14px;
    
    }
    .questionnoEmp{
margin:0rem .5rem 0rem .5rem;

    }
    .faqEmpCloseBtn{
      width: 70%;
    }
  
  }
  @media only screen and (min-width:601px ) and (max-width:1000px) {
    .faqEmp_Container {
      width: 72%;
      height: 77%;
      /* margin-left: -5rem; */
    }
    .itemEmp{
      padding: .7rem;
      text-align: justify;
      font-size: 21px;
    
    }
    .title_headingEmp{
      font-size: 21px;
    }
    .questionnoEmp{
margin:0rem .5rem 0rem .5rem;

    }
    .faqEmpCloseBtn{
      width: 70%;
    }
  
  }