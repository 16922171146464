.createpopupplan_container {
  width: 90%;
  height: 100%;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0%;
  left: 4rem;
  z-index: 999;
  margin: auto;
  background: rgba(32, 32, 32, 0.8);
}
.cpp_container {
  width: 50%;
  height: 90%;
  border-radius: 10px;
  background-image: url("../../../public//employer/background.jpeg");
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50vh;
  /* width: 1274px; */
  /* height: 1360px; */
  /* border-radius: 12px; */
  background-color: white;
  /* border: 2px solid red; */
  /* box-shadow: rgba(31, 30, 30, 0.35) 0px 5px 15px; */
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.create_own_close_btn{
    width: 100%;
    display: flex;
    justify-content: right;
}
.create_own_close_btn button{
    border: none;
    background: transparent;
    font-size: 20px;
    cursor: pointer;
}
.cppform_card {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: -1rem;
  /* box-shadow: 0px 4px 60px rgba(0,0,0,0.25); */
}
.cppform_card .input_field {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  gap: 1rem;
}
.VH_IF,
.EFI,
.SP,
.CN,
.skills {
  width: 95%;
  display: flex;
  margin: 0.2rem;
  color: black;
  font-weight: 600;
  font-size: 14px;
  font-family: Poppins;
}
.VH_IF select {
  width: 94%;
  border: 3px solid rgba(247, 112, 29, 0.39);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.8rem;
  color: black;
  font-weight: 600;
  font-size: 14px;
  font-family: Poppins;
}
.skills select {
  width: 95%;
  border: 3px solid rgba(247, 112, 29, 0.39);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.8rem;
  /* color: #898787; */
  color: black;
  font-weight: 600;
  font-size: 14px;
  font-family: Poppins;
}
.SP input {
  width: 94.5%;
  border: 3px solid rgba(247, 112, 29, 0.39);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
}
.CN input {
  width: 95%;
  border: 3px solid rgba(247, 112, 29, 0.39);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
}
.E,
.F,
.I {
  width: 31.2%;
  margin: 0.1rem;
}
.E input {
  width: 98%;
  border: 3px solid rgba(247, 112, 29, 0.39);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
}
::placeholder {
  color: black;
  font-weight: 600;
  font-size: 14px;
  font-family: Poppins;
}
.F input {
  width: 98%;
  border: 3px solid rgba(247, 112, 29, 0.39);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 1rem;
}
.I select {
  width: 100%;
  border: 3px solid rgba(247, 112, 29, 0.39);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.8rem;
  /* color: #898787; */
  color: black;
  font-weight: 600;
  font-size: 14px;
  font-family: Poppins;
}
.btn_sp {
  width: 86%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;
  margin-bottom: 0.5rem;
  /* border: 2px solid black; */
}
.btsp {
  width: 30%;
  border-radius: 50px;
  background: rgba(247, 112, 29, 0.39);
  border: none;
  padding: 0.5rem;
}
@media only screen and (max-width:950px){
.createpopupplan_container{
  left: 1.3rem;
}
.cpp_container{
  width: 90%;
}
}