.applied_main_job{
    display: flex;
    flex-direction: row;
    /* background-image: url("../../../public/jobseeker/background.jpeg"); */
    /* background-position: bottom right;
    background-repeat: no-repeat; */
}
.Applied_Job_content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.Applied-Job-Cards-Section {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
    padding-top: 0px;
    z-index: 998;
    height: 80vh;
    overflow-y: scroll;
    margin-top: .5rem;
    margin-bottom: .5rem;
}

.Job-Applied-Card {
    width: 98%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    /* border: 2px solid green; */
    /* border: 5px solid red; */

}

.Job-Applied {
    width: 75%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    /* padding-left: 1rem; */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    gap: 1rem;
    /* border: 2px solid red; */
    background: #FFFFFF;

    /* padding-top: 1rem; */
    /* padding-bottom: 1rem; */
    padding: 2rem 2rem 2rem 2rem;
    /* border: 5px solid blue; */
    /* padding: 1.1rem; */
}

.Applied-JOB-Button {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* margin: ; */
    margin: 1.1rem 0rem 0rem 0rem;
    /* border: 5px solid green; */
}

.Applied_job_btn {
    width: 55%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: rgba(82, 171, 28, .4);
    border: none;
    border: 2px solid #52AB1C;
    cursor: pointer;
    font-size: large;
    padding: .28rem;

}

.JOB-applied-Comp {
    width: 100%;
    /* border: 5px solid yellow; */
    margin: 0rem 0rem 0rem 0rem;
}

.JOB-applied-Comp h3 {
    /* margin: 0%; */
    /* margin: 0rem ; */
    margin-top: 0rem;
    font-weight: 600;
    /* line-height: 40px; */
    /* color: #898787; */
}

.JOB-applied-Comp h4 {
    margin: 0%;
    line-height: 30px;
    /* color: #898787; */
    color: #898787;
    font-weight: 500;
    /* font-weight: 400; */
}

.Applied_bookmark_img {
    /* border: 2px solid blue; */
    height: 100%;
    padding: 0.5rem;
}

.Applied_bookmark_img img {
    /* padding: 2rem; */
    width: 100%;
    height: 100%;
    /* border: 1px solid green; */
    /* border: 5px solid blue; */

}
@media only screen and (min-width:601px) and (max-width:900px){
    .Job-Applied-Card {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 1rem;
        /* border: 2px solid green; */
        /* border: 5px solid red; */
    }

}
@media only screen and (min-width:201px) and (max-width:600px){
    .Applied-Job-Cards-Section {
        width: 95%;
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 10%;
        bottom: 10%;
        align-items: center;
        padding-left: 0px;
        padding-top: 0px;
        z-index: 998;
        height: auto;
        overflow-y: scroll;
    }
    .applied_main{
        display: flex;
        flex-direction: column-reverse;
        /* background-image: url("../assets/Navbarbackground.jpeg"); */
        background-position:right;
        background-repeat: no-repeat;
    }
    .Applied_Job_content {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
    .Job-Applied-Card {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 1rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 1rem;
        /* border: 2px solid green; */
        /* border: 5px solid red; */
    }
    .Job-Applied {
        width: 95%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        /* padding-left: 1rem; */
        box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
        border-radius: 20px;
        gap: 0.3rem;
        /* border: 2px solid red; */
        background: #FFFFFF;
        /* padding-top: 1rem; */
        /* padding-bottom: 1rem; */
        padding: 2rem 2rem 2rem 2rem;
        /* border: 5px solid blue; */
        padding: 1.1rem;
    }
    .Applied-JOB-Button {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin: 0.3rem 0rem 0rem 0rem;
        /* border: 5px solid green; */
    }
    .JOB-applied-Comp h3 {
        /* margin: 0%; */
        /* margin: 0rem; */
        margin-top: 0rem;
        font-weight: 600;
        /* line-height: 40px; */
        /* color: #898787; */
    }
    .JOB-applied-Comp h4 {
        margin: 0%;
        line-height: 24px;
        /* color: #898787; */
        color: #898787;
        font-weight: 500;
        /* font-weight: 400; */
    }
    .Applied_job_btn {
        width: 62%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: rgba(82, 171, 28, .4);
        border: none;
        border: 2px solid #52AB1C;
        cursor: pointer;
        font-size: small;
        padding: 0.2rem;
    }
    

}