.homedashboard_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-family: Poppins;
    /* border: 2px solid black; */
}

.homedashboard_heading {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    /* border: 2px solid black; */
    /* transform: skew(-20deg);
background: #f7701d;
border-radius: 10px; */


}

.heading1 {
    width: 15%;
    height: 4rem;
    
    transform: skew(-20deg);
    background: #F7701D;
    border-radius: .5rem;
    border: none;
    color: #ffff;
    display: flex;
    justify-content: center;
    align-items: center;

}

.heading2 {
    width: 50%;
    /* border: 2px solid red; */
    height: 3rem;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    padding: 1rem;
    transform: skew(-20deg);
    background: #C5EEED;
}

.homedashboard_content {
    width: 90%;
    display: flex;
    flex-direction: row;
}

.homedashboard-leftcontent {
    width: 80%;
    gap: 2rem;

}

.homedashboard_right_content img {
    width: 80%;

}

.dashboard_card {
    width: 90%;
    /* justify-content: center;
    height: 12vh; */

    background: #ffff;

    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    margin: .7rem 0rem 0.7rem 0rem;
    /* border: 2px solid black; */
}

.dashboardcard_content {
    padding: 1rem 0rem 0.3rem 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;


}

.dashboardcard_content h5 {
    font-family: Poppins;
    margin: 0;
    /* border: 2px solid black; */
}

.dashboardcard_content p {

    font-family: Poppins;
    font-size: 13.8px;
    font-weight: 600;
    /* border: 2px solid black; */
    margin-left: 0rem;
}

.head4 {
    /* border: 2px solid black; */
    width: 90%;
    margin-top: .6rem;
    margin-left: 1rem;
    /* text-align: center; */
}

@media only screen and (max-width: 830px) {
    .heading1 {
        width: 20%;
    }

    .heading2 {
        width: 80%;
    }

    .homedashboard_content {
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .homedashboard-leftcontent {
        width: 100%;
       
    }

    .dashboardcard_content {
        width: 100%;
        /* border: 2px solid black; */
        
    }
    .dashboard_card{
       width: 100%;
    }
    .homedashboard_right_content  {
        width: 75%;
    }

}
@media only screen and (max-width: 450px){

    .heading1 h3{
        font-size: .9rem;
        
    }
    .heading1{
        width: 30%;
        height: 3.5rem;
        
    }
    .homedashboard_right_content  {
        display: none;
    }
}