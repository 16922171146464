.Services {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
*{
  box-sizing: border-box;
}
.services{
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.servicecarousel_container{
  width: 90%;
  margin-left: 7rem;
}
.servicecontainer_heading{
  display: flex;
  width: 100%;
  flex-direction: row;
  /* margin: auto; */
  /* margin-left: 4rem; */
  justify-content: space-between;
}
.service-leftheading{
  width: 40%;
  padding: 2rem;
margin-left: 7rem;
}
.service-rightheading{
  width: 60%;
  padding: 2rem;
  margin-top: -2rem;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: #ECF9F9;

}
.service-Carousel{
  width: 100%;
  /* margin-left:1rem; */
  margin-top: -3rem;
  /* margin-left:1rem; */
  margin-top: 0rem;

  z-index: 1;
}
.service-Carousel-Track{
  width: 100%;
}


.service_img {
  width: 100%;
  height: 100%;
}
.service_card_heading {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
}
.service_card_content {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  color: #000000;
  text-align: justify;
  width: 80%;
  padding: 7%;
}
.service_right_para {
  width: 100%;
  font-family: "Poppins",sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
  padding: 2%;
}
.Services-card-job{
  width: 100%;
    display: flex;
    flex-direction: row;
    gap: 2rem;
}
.service-Card{
  width: 80%;
  height: 40vh;
  /* height: auto; */
  display: flex;
  flex-direction: column;
  /* border: 2px solid pink; */
  gap: 1rem; display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0px 4px 50px rgba(140,221,220,0.5);
  border-radius: 20px;
  padding: 1.5rem;
margin: 2rem 0rem 2rem 2rem;
}
.Services-txt h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 70px;
}
.Services-card {
  width: 89%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 2rem;
}

.Services-card .Services-card-data {
  box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
  border-radius: 20px;
  width: 320px;
  height: 275px;
  padding: 0rem 0.3rem 0.2rem 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}

.Services-card .Services-card-data .card-data-head {
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
}
.Services-card .Services-card-data h6 {
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  margin: -0.5rem 0rem 0rem 0rem;
}
.Services-card .Services-card-data img {
  margin-top: 0.8rem;
  width: 137px;
  height: 118px;
}
@media only screen and (min-width: 1001px) and (max-width: 1300px) {
  .Services-card .Services-card-data h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    margin: -0.5rem 0rem 0rem 0rem;
  }
}
@media only screen and (min-width: 901px) and (max-width: 1000px) {
  .Services-card .Services-card-data h6 {
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    text-align: center;
    margin: -0.5rem 0rem 0rem 0rem;
  }
  .Services-card {
    width: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    /* border: 2px solid red; */
  }
  .services{
    width: 100%;
  }
  .servicecarousel_container{
    width: 100%;
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
}

@media only screen and (min-width: 801px) and (max-width: 900px) {
  .Services-card {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    /* border: 2px solid red; */
  }
  .Services-card .Services-card-data {
    box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
    border-radius: 20px;
    width: 70%;
    height: 275px;
    padding: 0rem 0.3rem 0.2rem 0.3rem;
    /* border: 5px solid blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
  .services{
    width: 100%;
  }
  .servicecarousel_container{
    width: 100%;
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
}
@media only screen and (min-width: 701px) and (max-width: 800px) {
  .Services-card {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .Services-card .Services-card-data {
    box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
    border-radius: 20px;
    width: 70%;
    height: 275px;
    padding: 0rem 0.3rem 0.2rem 0.3rem;
    /* border: 5px solid blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
  .services{
    width: 100%;
  }
  .servicecarousel_container{
    width: 100%;
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
}
@media only screen and (min-width: 601px) and (max-width: 700px) {
  .Services-card {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .Services-card .Services-card-data {
    box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
    border-radius: 20px;
    width: 70%;
    height: 275px;
    padding: 0rem 0.3rem 0.2rem 0.3rem;
    /* border: 5px solid blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
}
/* @media only screen and (min-width: 201px) and (max-width: 580px) {} */
@media only screen and (min-width: 201px) and (max-width: 580px) {
  .Services-card {
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .Services-card .Services-card-data {
    box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
    border-radius: 20px;
    width: 90%;
    height: 275px;
    padding: 0rem 0.3rem 0.2rem 0.3rem;
    /* border: 5px solid blue; */
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    cursor: pointer;
  }
  .servicecarousel_container{
    width: 100%;
    margin-left: 0rem;
    display: flex;
    flex-direction: column;
}
.servicecontainer_heading{
  flex-direction: column;
  gap: 2rem;
  padding: 10% 0% 0% 0%;
}
.service-leftheading{
  width: 100%;
  text-align: center;
  padding: 0rem;
margin-left: 0rem;
}
.service-rightheading{
  width: 100%;
  margin-top: 0rem;
  display: flex;
  background: #ECF9F9;
  padding: 0%;
}
.service-Carousel{
  width: 100%;
  margin-left:0rem;
  margin-top: 0rem;
  z-index: 1;
  background: #ECF9F9;
}
.service-Carousel-Track{
  width: 100%;
  padding: 0rem;
  gap: 0rem;
}
.service-Card{
  background: white;
}
}