.DashBoardTour-background14{
    width: 40%;
    display: flex;
    position: fixed;
    border: none;
    outline: none;
    justify-content: center;
    align-items: center;
    left: 44.4rem;
    top: 25.8rem;
  }