.viewjob_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0%;
  left: 0rem;
  z-index: 999;
  margin: auto;
  background: rgba(32, 32, 32, 0.7);
}
.viewjobcontainer {
  width: 60%;
  height: 95%;
  background: url("../../../public/employer/background.jpeg");

  background-repeat: no-repeat;
  background-position: top right;
  background-size: 50vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  /* margin-top: 1rem; */
  border-radius: 12px;
}
.viewdjob-Cards-Section {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0px;
  padding-top: 0px;
  z-index: 998;
  overflow-y: scroll;
}
.viewdjob-Card {
  width: 88%;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 1rem;
  margin-top: 1rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
}
.job-Card {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 1rem;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 2rem;
  gap: 0.2rem;
}
.job-Card h4 {
  color: #898787;
}
.jOB-Name h2 {
  margin: 0;
}
.jOB-Name h4 {
  margin: 0;
}
.earning h4 {
  margin: 0;
}
.job_descriptions {
  display: flex;
  flex-direction: column;
}
.job_descriptions h2,
h3,
p {
  margin: 0.5rem;
}
.job_descriptions p {
  color: #898787;
}
.job_descriptions ul {
  margin-left: 1.7rem;
}
.fulljob_description .none {
  list-style: none;
  margin: 0rem 0rem 1rem 0.4rem;
}
.fulljob_description .gray {
  color: #898787;
  margin: 0rem 0rem 1rem 1.7rem;
}
.fulljob_description li::marker {
  color: black;
  font-size: 20px;
}
.job_descriptions .JD_heading {
  display: flex;
  margin-left: 0.6rem;
}
.linkedin_page h3,
p {
  margin-left: 0.6rem;
}
.applyjob_btn{
  width: 25%;
  border: none;
  background: rgba(247,112,29,0.39);
  border-radius: 12px;
  padding: .7rem;
}
@media only screen and (max-width:650px){
  .viewjobcontainer{
    width: 90%;
  }
}
@media only screen and (max-width:950px){
  .viewjobcontainer{
    width: 80%;

  }
}