.DashBoardTour-background2{
    /* background-color: rgb(180, 180, 180); */
    /* position: fixed;
    left: 2rem;
    top: 0rem; */
    width: 40%;
    /* height: 100vh; */
    display: flex;
    position: fixed;
    border: none;
    outline: none;
    justify-content: center;
    align-items: center;
    left: 18.7rem;
    top: 12rem;
  }
  .close_popup{
    display: none;
  }
  
  .DashBoardTour-card-card2{

    background-color: white;
    border-radius: 20px;
    width: 80%;
    /* border:2px solid red; */
  }
  
  .DashBoardTour-card-card-child2{

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  
  }
  .DashBoardTour-card-card-child-top2{
    display: flex;
    justify-content: flex-end;
    height: 10%;

  }
  .DashBoardTour-card-card-child-top2 > div{
    display: flex;
    justify-content: space-between;
    width: 70%;
    height: 10%;
    margin-right: 5%;

  }
  .DashBoardTour-card-card-child-bottom2{
    display: flex;
    justify-content: flex-end;
    margin-right: 5%;
  }
  .DashBoardTour-card-box2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    /* border:2px solid black; */
    /* position: fixed; */
    


  }
  .DashBoardTour-card-box2 > img{
    width: 10%;
  }
  .DashBoardTour-card-card-child-middle2 > h3{
    text-align: center;
  }
  .DashBoardTour-card-arrow2 {
    width: 20%;
    margin-bottom: 15%;
  }
  
  
  .DashBoardTour-card-card-child-top2 > div > h3{
    margin-left: 13%;
    font-weight: 600;
  }
  .DashBoardTour-card-card-child-top2 > div > p{
    color: #F7701D;
    font-weight: 600;
    font-size: 110%;
    
  }
  .DashBoardTour-card-card-child-bottom2 > p{
    color: #F7701D;
    font-weight: 600;
    font-size: 110%;
  }
  .DashBoardTour-card-card-child-top2 > div > h3{
    font-weight: 600;
  }