.DashBoardTour-background10{
    /* background-color: rgb(180, 180, 180); */
    /* position: fixed;
    left: 2rem;
    top: 0rem; */
    width: 40%;
    /* height: 100vh; */
    display: flex;
    position: fixed;
    border: none;
    outline: none;
    justify-content: center;
    align-items: center;
    left: 9.7rem;
    top: 25.8rem;
  }
  .DashBoardTour-card-box10{
    display: flex;
    flex-direction: column;
    /* border: 3px solid green; */
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-family: 'Poppins', sans-serif;
  }
  .DashBoardTour-card-arrow21{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }