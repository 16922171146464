.DashBoardTour-background11{
  /* background-color: rgb(180, 180, 180); */
  /* position: fixed;
  left: 2rem;
  top: 0rem; */
  width: 40%;
  /* height: 100vh; */
  display: flex;
  position: fixed;
  border: none;
  outline: none;
  justify-content: center;
  align-items: center;
  left: 26.7rem;
  top: 25.8rem;
}