.background_img{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-image: url("../../../public/employer/backgroundnewnewimg.jpg");
    background-position: right bottom;
    background-repeat: no-repeat;
}
.create_job_post{
    display: flex;
    flex-direction: row;
}
.create_job_content{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-position:right bottom ;
    background-repeat: no-repeat;
}
.CreateJobPost{
    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* justify-content: flex-start; */
    height: 80vh;
    margin-top: 1rem;
    overflow-y: scroll;
}
.createjobpost{
    width: 85%;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    /* justify-content: flex-start; */
    align-items: center;
    /* gap: 2rem; */
}
.createjobpost .JObPost-col{
    display: flex;
    margin-top: 2rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 50px rgba(247, 112, 29, 0.39);
    border-radius: 20px;
    width: 100%;
    height: 90px;
    
}
.JObPost-col .I-BTN{
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 25px;
    margin-left: 1.5rem;
    font-weight: 550;
    /* margin-left: -0.5rem; */
}
.JObPost-col p{
    font-size: 20px;
    margin-left: 1.5rem;
    font-weight: 550;
}
.CreateJob_btn{
    /* position: relative; */
    background: rgba(247, 112, 29, 0.39);
    border-radius: 10px;
    width: 220px;
    height: 58px;
    border: none;
    cursor: pointer;
    font-weight: 500;
    margin-right: 1.5rem;
    font-size: 20px;
    line-height: 30px;

}

.Write-to-Create-form-Container{
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0%;
    left: 0%;
    z-index: 999;
    background: rgba(32, 32, 32, 0.8);
}

.Create-Sub-plan{
    background-color:white;
    width: 45%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 9px;
}
.Create-top{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.Create-CloseBTN{
    border: none;
    font-size: x-large;
    text-decoration: solid ;
    font-weight: 1000;
    cursor: pointer;
    justify-content: flex-end;
    /* border: 1px solid green; */
    /* margin-left: -3rem; */
    margin-right: 2rem;
    margin-top: 1rem;
    top: 1rem;
    background: transparent;
}
.Create-Jobpost{
    font-weight: 500;
    width: 100%;
    padding: 0rem 0rem 2rem 2rem;
    background-image: linear-gradient(to right bottom, 
    #FFFFFF, rgba(21, 185, 184, 0.6));
    border-radius: 9px;
    /* border: 2px solid red; */
}
.Create-Jobpost>ul>li{
    font-family: 'Poppins',sans-serif;
    font-size: 15px;
    font-weight: 400;
    line-height: 1.5rem;

}
.BTN-CreateJobPost{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.BTN-CreateJobPost .BTN-CreateJobpost{
    width: 200px;
    height: 41px;
    background: rgba(247, 112, 29, 0.39);
    border-radius: 10px;
    color: black;   
    font-size: x-large;
    border: none;
    cursor: pointer;
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;
    /* position: relative; */
    right: 12rem;
}
@media only screen and (min-width:201px) and (max-width:600px){
    .background_img{
        height: 100vh;
    }
    .create_job_content{
        align-items: center;

    }
    .CreateJobPost{
        flex-direction: column;
        bottom: 10%;
        top:10%;
        position: fixed;
        height: auto;
    }
    .createjobpost{
        width: 90%;
        flex-direction: column;
        font-size: 18px;
        gap: 0rem;
    }
    .createjobpost .JObPost-col{
        margin-top: 1rem;
        flex-direction: column;
        justify-content: space-evenly;
        height: 20vh;
        
    }
    .Create-Sub-plan{
        width: 95%;
        padding: .8rem;
    }
    .Create-CloseBTN{
        margin-left: 0rem;

    }
}
@media only screen and (min-width:601px) and (max-width:900px){
    .background_img{
        height: 100vh;
    }
    .CreateJobPost{
        flex-direction: column;
        bottom: 10%;
        top:10%;
        position: fixed;
        height: auto;
    }
    .createjobpost{
        width: 90%;
        flex-direction: column;
        font-size: 18px;
        gap: 0rem;
    }
    /* .createjobpost .JObPost-col{
        margin-top: 1rem;
        flex-direction: column;
        justify-content: space-evenly;
        height: 20vh;
        
    } */
    .Create-Sub-plan{
        width: 95%;
        padding: .8rem;
    }
    .Create-CloseBTN{
        margin-left: 40rem;

    }

}
@media only screen and (min-width:901px) and (max-width:1280px){
    /* .background_img{
        height: 100vh;
    }
    .CreateJobPost{
        flex-direction: column;
        bottom: 10%;
        top:10%;
        position: fixed;
        height: auto;
    } */
    .createjobpost{
        width: 90%;
        flex-direction: column;
        font-size: 18px;
        gap: 0rem;
    }
    .Create-Sub-plan{
        width: 70%;
        padding: .8rem;
    }
    .Create-CloseBTN{
        margin-left: 40rem;
        /* margin-top: 1rem; */

    }

}