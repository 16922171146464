.Job_posted_main_content{
    display: flex;
    flex-direction: row;
}
.Job_Posted_content{

    display: flex;
    flex-direction: column;
    width: 100%;
    background-position:right bottom ;
    background-repeat: no-repeat;
    /* background-image: url("../../../public/employer/background.jpeg"); */
}
.JobPosted{

    width: 95%;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 80vh;
    overflow-y: scroll;
    margin: 1rem 0rem 0rem 0rem;
}
.jobpost{
    width: 90%;
    
}
.Job-Head{
    width: 85%;
    display: flex;
    flex-direction: row;
    /* align-items: center; */
    justify-content: space-between;
    /* gap: 5rem; */
    margin-top: 1rem;
    /* margin-bottom: 1.5rem; */
    font-size: larger;
    padding: 1rem;
    /* border: 2px solid blue; */

}
.Job-Heads{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    /* gap: 5rem; */
    margin-top: 1rem;
    /* margin-bottom: 1.5rem; */
    font-size: larger;
    padding: 1rem;
    /* border: 2px solid blue; */

}
.Job-Head label{
    margin-left: -5rem;

}

.Job-body{
    width: 85%;
    padding: 1rem;

    display: flex;
    flex-direction: column;
    gap: 0.5rem;

}
.Job-card-Data{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    margin-bottom: 1.5rem;
    background: #FFFFFF;

}
.jobcard{
    width: 100%;
     display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    
}
.card-top{
    /* width: 722px; */
    width: 100%;
    height: 66px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    gap: 2rem;

    background-color: #67D6D5;
    border-radius: 20px 20px 0px 0px;
}
.card-top option{
    background-color:#fff;
}

.card-top .BTN-Top{
    font-weight: 500;
    border: none;
    background-color: #67D6D5;
    font-size: 18px;
    margin-left: 0.5rem;
    cursor: pointer;
}
.job-card-head{
    display: flex;
    flex-direction: row;
    gap: 20rem;
    width: 95%;
    /* border: 2px solid black; */
    justify-content: space-between;

    margin-top: 1rem;
    margin-left: 1.5rem;
}
.job-card-head p{
    font-weight: bold;
}
.job-card-body{
    display: flex;
    flex-direction: column;
    margin-left: 1.0rem;
    color: gray;
    /* border: 2px solid black; */

}
.job-card-foot{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5rem;
    margin: 1rem 0rem 1rem 1.5rem ;

}
.job-card-foot .BTN-save{
    background-color: #F7701D;
    border-radius: 1rem;
    width: 119px;
    height: 36px;
    font-weight: bold;
    color: white;
    border: none;
    cursor: pointer;
}
.Write-to-form-Container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0%;
    left: 0%;
    z-index: 999;
    background: rgba(32, 32, 32, 0.8);
  }
  .Close-BTN{
    position: absolute;
    left: 95%;
    background-color: rgba(202, 23, 113, 0);
    border:none;
    font-size: 2rem;
    cursor: pointer;
}
.Sub-plan{
    background-color:rgb(238, 236, 236);
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}
.share-top{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30rem;
    font-size: x-large;
    font-weight: bolder;
}
.share-top  button{
    border: none;
    font-size: x-large;
    text-decoration: solid ;
    font-weight: 1000;
    cursor: pointer;
}
.share-icons{
    width: 90%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6rem;
    font-size: 3rem;
}
.share-icons p{
    cursor: pointer;
}
.BTN-Sh{
    display: flex;
    flex-direction: column;
}
.BTN-Sh .BTN{
    width: 250px;
    height: 51px;
    background: #F7701D;
    border-radius: 10px;
    color: white;
    font-size: x-large;
    border: none;
    cursor: pointer;
}

@media only screen and (min-width:200px) and (max-width:500px){
    .Job_posted_main_content{
        display: flex;
        flex-direction: column-reverse;
    }
    .JobPosted {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 8rem;
        bottom: 3rem;
        height: auto;
        overflow-y: scroll;
        margin: 0px 0px 0px 10px;
    }
    .jobpost{
        width: 100%;
    }
    .Job-Head {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        font-size: larger;
        /* border: 2px solid red; */
    }
    .Job-Head label {
        margin-left: -1.5rem;
    }
    .Job-body {
        width: 95%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .card-top {
        width: 100%;
        height: 66px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.1rem;
        background-color: #67D6D5;
        border-radius: 20px 20px 0px 0px;
    }
    .job-card-head{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7rem;
        justify-content: center;
        margin-top: 1rem;
        margin-left: 1.5rem;
    }
    .job-card-foot {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0rem;
        margin: 1rem 0rem 1rem 1.5rem;
    }
    .card-top .BTN-Top {
        font-weight: 500;
        border: none;
        background-color: #67D6D5;
        font-size: 10px;
        margin-left: 0.5rem;
        cursor: pointer;
    }

    
}
@media only screen and (min-width:500px) and (max-width:700px){
    .Job_posted_main_content{
        display: flex;
        flex-direction: column-reverse;
    }
    .JobPosted {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: fixed;
        top: 8rem;
        bottom: 3rem;
        height: auto;
        overflow-y: scroll;
        margin: 0px 0px 0px 10px;
    }
    .jobpost{
        width: 100%;
    }
    .Job-Head {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 2rem;
        width: 100%;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        font-size: larger;
        /* border: 2px solid red; */
    }
    .Job-Head label {
        margin-left: -1.5rem;
    }
    .Job-body {
        width: 95%;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }
    .card-top {
        width: 100%;
        height: 66px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.1rem;
        background-color: #67D6D5;
        border-radius: 20px 20px 0px 0px;
    }
    .job-card-head{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 7rem;
        justify-content: center;
        margin-top: 1rem;
        margin-left: 1.5rem;
    }
    .job-card-foot {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0rem;
        margin: 1rem 0rem 1rem 1.5rem;
    }
    .card-top .BTN-Top {
        font-weight: 500;
        border: none;
        background-color: #67D6D5;
        font-size: 10px;
        margin-left: 0.5rem;
        cursor: pointer;
    }

    /* .Job-card-Data{
        margin-left: 5rem;
    } */
}

@media only screen and (min-width:700px) and (max-width:900px) {
    .card-top {
        width: 100%;
        height: 66px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
        /* gap: 2rem; */
        background-color: #67D6D5;
        border-radius:
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        20px 20px 0px 0px;
    }
    .Job-card-Data {
        width: 99%;
        display: flex;
        flex-direction: column;
        align-items: center;
        box-shadow: 0px 4px 20px rgb(0 0 0 / 25%);
        border-radius: 20px;
        margin-bottom: 1.5rem;
        /* border: 2px solid red; */
    }
    .card-top .BTN-Top {
        font-weight: 500;
        border: none;
        background-color: #67D6D5;
        font-size: 10px;
        margin-left: 0.5rem;
        cursor: pointer;
    }
    .job-card-head {
        display: flex;
        flex-direction: row;
        gap: 12rem;
        margin-top: 1rem;
        margin-left: 1.5rem;
    }
    .job-card-foot {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 3rem;
        margin: 1rem 0rem 1rem 1.5rem;
    }
    .JobPosted {
        width: 95%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 60vh;
        overflow-y: scroll;
        margin: 0px 0px 0px 0px;
    }
    
}