.WhatClientSays {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1rem;
  /* border: 2px solid red; */
}

.carousel-root {
  outline: none;
  width: 100%;
}
.whatclientsays {
  width: 90%;
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
}
.WhatClientSays-Head h1 {
  font-family: 'Poppins',sans-serif;
font-style: normal;
font-weight: 600;
font-size: 25px;
line-height: 38px;
color: #000000;

}
.name_location {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.WhatClientSays-car {
  /* width: 1043px; */
  width: 80%;
  background: #ffffff;
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); */
  border-radius: 20px;
  margin-bottom: 2rem;
}

.WhatClientSays-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 1rem;
  background: #ecf9f9;
  gap: 1rem;
}
.carousel.carousel-slider .control-arrow:hover {
  /* background: rgba(0, 0, 0, 0.2); */
  background: #f7701d;
  border-radius: 20px;
}
.carousel .control-dots {
  bottom: 0;
  background: #ecf9f9;
  /* background: #000000; */
  /* color */
  margin: 0%;
}
.carousel .control-dots .dot {
  background: #f7701d;
}
.carousel.carousel-slider .control-arrow {
  top: 50%;
  /* border-radius: 50%; */
  border-radius: 20px;
  margin: 0rem 1rem 0rem 1rem;
  visibility: visible;


  background: #f7701d;
  height: 10%;
  padding: 5px;
}
.WhatClientSays-card .card-head-what {
  /* width: 1043px; */
  width: 100%;
  height: 27.51px;
  background: #f7701d;
  margin-bottom: 0.5rem;
  border-radius: 20px 20px 0px 0px;
}
.WhatClientSays-card img {
  width: 122px;
  height: 122px;
}
.WhatClientSays-card .What-Name {
  font-weight: 500;
  font-size: 26px;
  line-height: 65px;
  text-align: left;
  padding-left: 1.3rem;
  /* gap: 1rem; */
  color: #000000;
}
.WhatClientSays-card .What-loc {
  font-weight: 500;
  font-size: 20px;
  line-height: 45px;
  text-align: left;
  margin-top: -2rem;
  padding-left: 1.2rem;
  color: #000000;
  /* color: rgba(96, 93, 93, 0.8); */
}
.WhatClientSays-card .what-message-1 {
  margin-top: -1rem;
  font-weight: 500;
  font-size: 20px;
  line-height: 35px;
  color: #000000;
  padding: 0rem 3.5rem 0rem 3.5rem;
  text-align: justify;
  /* border: 2px solid red; */
}
.WhatClientSays-card .what-message-2 {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #000000;
  margin-top: -1rem;
  margin-bottom: 0.8rem;
}
@media only screen and (min-width: 601px) and (max-width: 1000px) {
  .carousel-root {
    outline: none;
    width: 100%;
  }
  .WhatClientSays-car{
    width: 100%;
  }


}
@media only screen and (min-width: 201px) and (max-width: 580px) {
  .WhatClientSays-Head h1 {
    margin: 1rem;
  }
  .carousel-root {
    outline: none;
    width: 100%;
  }
  .WhatClientSays-car {
    width: 100%;
  }
  .whatclientsays{
    width: 98%;
  }
  .WhatClientSays-card .what-message-1 {
    padding: 0rem 1.7rem 0rem 1.7rem;
  }
  .carousel.carousel-slider .control-arrow {
    margin: 0rem;
}
}
