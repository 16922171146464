.deletepopup_container{
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top:0%;
    left:0rem;
    z-index: 999;
    margin: auto;
    background: rgba(32,32,32,.7);
}
.dp_container{
    width: 50%;
    height: 80%;
background-image: url("../../../public/employer/background.jpeg");
background-repeat: no-repeat;
background-position: top right;
background-size: 50vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 12px;

}
.dpcontainer_content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    padding: 2rem;
}
.delete_btn{
    width:90%;
    display: flex;
    justify-content: space-between;
    /* border-radius: 20px;
    background: rgba(247,112,29,0.39);
    border: none;
    padding: .5rem;
    font-size: 17px; */

}
.delete_btn .delete_yes{
    width: 25%;
     border-radius: 10px;
     background: #FFF;
    border: 3px solid #F7701D;
    padding: .4rem;
    font-size: 17px;
   
}
.delete_yes:hover{
    background: rgba(247,112,29,0.39);

}

.dpcontainer_content img{
    width: 70%;
}
.dpcontainer_content p{
    font-weight: 600;
    font-size: 20px;
}