.flexibleplan_container {
  width: 100%;
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Poppins;
  font-style: normal;
  margin-top: 2rem;
  /* border:2px solid red; */
  background-color: #ECF9F9;
  background-image: url(../../../public/employer/flexibleplan_bcg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.flexibleplancontent_container {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFF;
  border-radius: 0% 0% 50% 50%;
}

.flexiblecard_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 3rem;
  margin-bottom: 3rem;

}

.flexibleplan_container h2 {
  line-height: 35px;
  font-weight: 600;
  font-size: 28px;
  margin-top: -1rem;
}

.tracking-in-contract {
  -webkit-animation: tracking-in-contract 1.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
  animation: tracking-in-contract 1.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

@-webkit-keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

@keyframes tracking-in-contract {
  0% {
    letter-spacing: 1em;
    opacity: 0;
  }

  40% {
    opacity: 0.6;
  }

  100% {
    letter-spacing: normal;
    opacity: 1;
  }
}

.flexiblecard {
  width: 25%;
  padding: 2.8rem;
  height: 45vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(180deg, #8CDDDC 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  z-index: 0;
  gap: 1.5rem;
}

.flexiblecard h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 3.5rem;
}
.Fcard_content{
  width: 100%;
  /* border: 3px solid red; */
}
.Fcard_content ul {
  width: 100%;
  /* border: 3px solid green; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: .5rem;
}

.Fcard_content ul li {
  font-size: 1.1rem;

}

.flexiblecard:hover {
  font-size: 2.0rem;
  z-index: 1;
  /* margin-bottom: 5rem; */
  transform: scale(1.05);
  background: linear-gradient(180deg, #8CDDDC 0%, #FFFFFF 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
}

.flexiblecard li:hover::marker {
  color: white
}




.flexiblecard li::marker {
  font-size: 1.5rem;
  color: rgba(103, 214, 213, .7);
}

.F_btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: .7rem;
  margin-bottom: .8rem;
}
.link_btn{
  width: 100%;
  text-decoration: none;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.F_btn .btn_flexible {
  width: 35%;
  padding: .5rem;
  border: none;
  background: #F7701d;
  border-radius: .4rem;
  /* cursor: pointer; */
  color: #FFFF;
  font-size: .9rem;
}

@media only screen and (max-width:1100px) {
  .flexiblecard_container {
    width: 100%;
    padding: 0;
    /* margin: 0; */
    /* border: 2px solid black; */
  }





  .flexiblecard {
    width: 40%;
    height: auto;
    /* border: 2px solid red; */
    /* height: 45vh; */
    display: flex;
    margin: .5rem;
    /* margin: 5rem 5rem 5rem 5rem; */
    /* margin: 2rem 2rem 2rem 2rem; */
    gap: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

@media only screen and (max-width:900px) {
  .flexiblecard h3 {
    font-size: 1.3rem;
    font-weight: 600;
  }

  .flexiblecard_container {
    width: 100%;
    justify-content: center;
    /* border: 2px solid red; */
  }

  .flexiblecard {

    height: auto;
    /* height: 45vh; */
    display: flex;
    /* margin: 5rem 5rem 5rem 5rem; */
    margin: 2rem 2rem 2rem 2rem;
    gap: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Fcard_content ul li {
    font-size: .9rem;
  }

  .F_btn .btn_flexible {
    font-size: .7rem;
    width: 50%;
  }
}

@media only screen and (max-width:650px) {
  .flexiblecard_container {
    flex-direction: column;
    justify-content: center;
    gap: 1.5rem;

  }

  .flexiblecard {
    width: 70%;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

  }

  .F_btn .btn_flexible {
    font-size: .7rem;
    width: 35%;
  }

  .starthiring_left h3,
  h2 {
    font-size: 1.2rem;
  }

  .starthiring_left h3 {
    font-size: 1rem;
  }
}