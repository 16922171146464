.feedback_container{
    /* position: relative; */
    width: 100%;
    display: flex;
    flex-direction: column;    
    justify-content: center;
    /* border: 3px solid yellow; */
    height: 100%;
    align-items: center;
    margin-top: -180px;
}
.feedbackProfile_container{
    /* position: absolute; */
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /* border: 2px solid red; */
    height: 100%;
    /* margin-top: 4rem; */
}
.FB_left{
    width: 50%;
    /* padding: 2rem; */
    margin-top: 1rem;
}
.FB_card_container{
    width: 40%;
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0,0,0,.25);
    border-radius: 20px;
    height: auto;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    padding: 0.3rem 1.9rem 0rem 1.9rem;
    /* align-items: center; */
}

.feedback_heading{
    text-align: center;
    /* font-size: 25px; */
    /* font-weight: 500; */
    font-family: "Poppins",sans-serif;
    font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 30px;
}
.feedback_sub_heading{
    text-align: center;
    /* font-size: 16px; */
    /* font-weight: 500; */
    font-family: "Poppins",sans-serif;
    font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 22px;
}
.fb_logo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    justify-content: center;
}
.textarea_form{
    width: 100%;
}
.FB_card_container .textarea textarea{
    border:2px solid #F7701D;
    /* font-family: ; */
    width: 100%;
    text-indent: 5px;
    margin-top: 1rem;
    padding: .5rem;
    color: #898787;
    font-size: 15px;

}
.FB_card_container .SUBMIT{
    width: 35%;
    border: none;
    height: 45px;
    display: flex;
    align-items: center;
    margin: auto;
    background: #F7701D;
    border-radius: 10px;
    color:#FFFF;
    font-family: "Poppins";
    justify-content: center;
    margin-top: 1.4rem;
    margin-bottom: 1.4rem;
    padding: .3rem;
}
@media only screen and (min-width: 1001px) and (max-width: 1100px) {
    .feedbackProfile_container {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        /* border: 2px solid red; */
        height: auto;
        margin-top: 4rem;
    }
    .FB_left {
        width: 50%;
        /* padding: 2rem; */
        margin-top: 1rem;
    }
    .FB_left img {
        width: 100%;
        /* height: 100%; */
    }
    .FB_card_container h3 {
        font-size: 25px;
        font-weight: 600;
        font-family: "Poppins";
        margin-bottom: 5px;
    }
    .FB_card_container h5 {
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        margin: 5px 0px 5px 0px;
        font-family: "Poppins";
    }
    .FB_card_container .textarea textarea {
        border: 2px solid #F7701D;
        width: 100%;
        height: 18vh;
    }
    .FB_card_container .SUBMIT {
        width: 55%;
        border: none;
        height: 45px;
        display: flex;
        align-items: center;
        margin: auto;
        background: #F7701D;
        border-radius: 10px;
        color: #FFFF;
        font-family: "Poppins";
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0.3rem;
    }
}
@media only screen and (min-width: 901px) and (max-width: 1000px) {
    .feedbackProfile_container {
        width: 80%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        /* border: 2px solid red; */
        height: auto;
        margin-top: 4rem;
    }
    .FB_left {
        width: 50%;
        /* padding: 2rem; */
        margin-top: 1rem;
    }
    .FB_left img {
        width: 100%;
        /* height: 100%; */
    }
    .FB_card_container h3 {
        font-size: 25px;
        font-weight: 600;
        font-family: "Poppins";
        margin-bottom: 5px;
    }
    .FB_card_container h5 {
        font-size: 16px;
        font-weight: 600;
        text-align: left;
        margin: 5px 0px 5px 0px;
        font-family: "Poppins";
    }
    .FB_card_container .textarea textarea {
        border: 2px solid #F7701D;
        width: 100%;
        height: 18vh;
    }
    .FB_card_container .SUBMIT {
        width: 55%;
        border: none;
        height: 45px;
        display: flex;
        align-items: center;
        margin: auto;
        background: #F7701D;
        border-radius: 10px;
        color: #FFFF;
        font-family: "Poppins";
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1rem;
        padding: 0.3rem;
    }
}

@media only screen and (min-width: 801px) and (max-width: 900px) {
    .feedbackProfile_container{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */
        margin-top: 4rem;
    }
    .FB_left img{
        width: 90%;
        /* padding: 2rem; */
        /* border: 3px solid yellow; */
        margin-top: 1rem;
    }
    .FB_left{
        width: 70%;
        /* padding: 2rem; */
        /* border: 3px solid green; */
        margin-top: 1rem;
    }
    .FB_card_container{
        width: 70%;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0,0,0,.25);
        border-radius: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 0.3rem 1.9rem 0rem 1.9rem;
        /* align-items: center; */
    }
  }
  @media only screen and (min-width: 701px) and (max-width: 800px) {
    .feedbackProfile_container{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */
        margin-top: 4rem;
    }
    .FB_left img{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid yellow; */
        margin-top: 1rem;
    }
    .FB_left{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid green; */
        margin-top: 1rem;
    }
    .FB_card_container{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0,0,0,.25);
        border-radius: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 0.3rem 1.9rem 0rem 1.9rem;
        /* align-items: center; */
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 700px) {
    .feedbackProfile_container{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */
        margin-top: 4rem;
    }
    .FB_left img{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid yellow; */
        margin-top: 1rem;
    }
    .FB_left{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid green; */
        margin-top: 1rem;
    }
    .FB_card_container{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0,0,0,.25);
        border-radius: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 0.3rem 1.9rem 0rem 1.9rem;
        /* align-items: center; */
    }
    .FB_card_container .SUBMIT {
        margin-bottom: 1.4rem;
    }
  }
  @media only screen and (min-width: 501px) and (max-width: 600px) {
    .feedbackProfile_container{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */
        margin-top: 4rem;
    }
    .FB_left img{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid yellow; */
        margin-top: 1rem;
    }
    .FB_left{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid green; */
        margin-top: 1rem;
    }
    .FB_card_container{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0,0,0,.25);
        border-radius: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 0.3rem 1.9rem 0rem 1.9rem;
        /* align-items: center; */
    }
  }
  @media only screen and (min-width: 401px) and (max-width: 500px) {
    .feedbackProfile_container{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */
        margin-top: 4rem;
        height: auto;
    }
    .FB_left img{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid yellow; */
        margin-top: 1rem;
    }
    .FB_left{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid green; */
        margin-top: 1rem;
    }
    .FB_card_container{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0,0,0,.25);
        border-radius: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 0.3rem 1.9rem 0rem 1.9rem;
        /* align-items: center; */
    }
    .FB_card_container .SUBMIT {
        width: 65%;
        border: none;
        height: 45px;
        display: flex;
        align-items: center;
        margin: auto;
        background: #F7701D;
        border-radius: 10px;
        color: #FFFF;
        font-family: "Poppins";
        justify-content: center;
        margin-top: 1.4rem;
        margin-bottom: 1.4rem;
        padding: 0.3rem;
    }
    
  }
  @media only screen and (min-width: 301px) and (max-width: 400px) {
    .feedbackProfile_container{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */
        margin-top: 4rem;
        margin-bottom: 3rem;
        height: auto;
    }
    .FB_left img{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid yellow; */
        margin-top: 1rem;
    }
    .FB_left{
        width: 100%;
        /* padding: 2rem; */
        /* border: 3px solid green; */
        margin-top: 1rem;
    }
    .FB_card_container{
        width: 100%;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0,0,0,.25);
        border-radius: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 0.3rem 1.9rem 0rem 1.9rem;
        /* align-items: center; */
    }
    .FB_card_container .SUBMIT{
        width: 85%;
        border: none;
        height: 45px;
        display: flex;
        align-items: center;
        margin: auto;
        background: #F7701D;
        border-radius: 10px;
        color:#FFFF;
        font-family: "Poppins";
        justify-content: center;
        margin-top: 1rem;
        margin-bottom: 1.4rem;
        padding: .3rem;
    }
  }
  @media only screen and (min-width: 201px) and (max-width: 300px) {
    .feedbackProfile_container{
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* border: 2px solid red; */
        margin-top: 4rem;
        height: auto;
    }
    .FB_left img{
        width: 90%;
        /* padding: 2rem; */
        /* border: 3px solid yellow; */
        margin-top: 1rem;
    }
    .fb_logo{
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 1rem;  
    }
    .FB_left{
        width: 70%;
        /* padding: 2rem; */
        /* border: 3px solid green; */
        margin-top: 1rem;
    }
    .FB_card_container{
        width: 70%;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(0,0,0,.25);
        border-radius: 20px;
        height: auto;
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        padding: 0.3rem 1.9rem 0rem 1.9rem;
        /* align-items: center; */
    }
    .FB_card_container .SUBMIT{
        width: 85%;
        border: none;
        height: 45px;
        display: flex;
        align-items: center;
        margin: auto;
        background: #F7701D;
        border-radius: 10px;
        color:#FFFF;
        font-family: "Poppins";
        justify-content: center;
        margin-top: 1.4rem;
        margin-bottom: 1.4rem;
        padding: .3rem;
    }
  }