.freelancejob_post_main_con{
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 1rem;
    /* border: 2px solid green; */

}
.freelance_jobpost{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    /* border: 2px solid red; */
    padding: 2rem;
    gap: .8rem;
    border-radius: 5px;
    box-shadow: 0px 4px 50px rgba(0,0,0,.39);
}



.freelancetextarea{
    width: 80%;
    /* border: 2px solid #0000; */
padding: 2rem;

}