.faqFrelancer_container {
    /* width: 100%;
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
      flex-direction: column; */
    width: 100%;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 0%;
    left: 0rem;
    /* border: 10px solid blue; */
    z-index: 999;
    margin: auto;
    background: rgba(32, 32, 32, 0.7);
  }
  .faqFrelancer_board {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* border: 3px solid blue; */
  }
  .questionnoFrelancer{
    color: #F7701D;
    font-size: 20px;
    margin: 0rem 2rem 0rem 2rem;
  
  }
  .faqFrelancerCloseBtn{
      width: 50%;
      /* height: 1rem; */
      /* border: 5px solid green; */
      display: flex;
      justify-content: flex-end;
  }
  .faqFrelancerCloseBtn button{
      /* width: 50%; */
      /* height: 5rem; */
      /* border: 5px solid green; */
      margin: 0rem 1.5rem -2.3rem 0rem;
      font-size: 1.7rem;
      border-radius: 7px;
      color: #000000;
      /* border: 2px solid black; */
      cursor: pointer;
      background: transparent;
      border: none;
      font-weight: 600;
      z-index: 3;
  }
  .faqFrelancer_Container {
    width: 50%;
    height: 95%;
    background-image: url("../../../public/jobseeker/background.jpeg");
    background-repeat: no-repeat;
    background-position: top right;
    background-size: 50vh;
    background-color: white;
    display: flex;
    overflow-y: scroll;
    border-radius: 10px;
    flex-direction: column;
    font-family: Poppins;
  
    /* width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly; */
  }
  .faqFrelancer_container_content {
    width: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .faqFrelancerup_container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 2rem;
  }
  
  .faqFrelancerup_left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60%;
  }
  .faqFrelancerup_right {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
  }
  .faqFrelancerup_right img {
    width: 100%;
    height: 100%;
  }
  .faqFrelancerup_left h1 {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .accordionFrelancer {
    width: 90%;
    margin: auto;
  }
  .itemFrelancer {
    background: #fff;
    margin-bottom: 0.6rem;
    margin-top: 1rem;
    border-radius: 15px;
    /* border:3px solid #51557E; */
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25); */
    box-shadow: 0px 4px 50px rgb(29 193 247 / 39%);
    padding: 1.2rem;
  }
  
  .title_headingFrelancer{
    margin: 0;
    font-size: 20px;
    line-height: 1.3rem;
    font-family: "Poppins",sans-serif;
  
  }
  .titleFrelancer {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    cursor: pointer;
  }
  .contentFrelancer {
    max-height: 0;
    overflow: hidden;
  }
  .contentFrelancer.showFrelancer {
    height: auto;
    max-height: 9999px;
    border-radius: 8px;
    background-color: rgba(247, 112, 29, 0.39);
    padding: 0.8rem;
    margin-top: 1rem;
  }
  @media only screen and (min-width:200px ) and (max-width:300px) {
    .faqFrelancer_Container {
      width: 72%;
      height: 72%;
      /* margin-left: -5rem; */
    }
    .itemFrelancer{
      padding: .3rem;
      text-align: justify;
      font-size: 11px;
    
    }
    .questionnoFrelancer{
  margin:0rem .5rem 0rem .5rem;
  
    }
    .title_headingFrelancer{
      font-size: 11px;
    }
    .faqFrelancerCloseBtn{
      width: 70%;
    }
    .faqFrelancerup_container{
      /* border: 2px solid red; */
  flex-direction: column;
    }
    .faqFrelancerup_left{
      width: 100%;
      text-align: justify;
      /* border: 2px solid red; */
  
    }
    .faqFrelancerup_left h1{
      font-size: 13px;
      font-weight: 600;
      margin: 0;
    }
    .faqFrelancerup_right img{
      display: none;
    }
  
  }
    @media only screen and (min-width:300px ) and (max-width:600px) {
    .faqFrelancer_Container {
      width: 72%;
      height: 72%;
      /* margin-left: -5rem; */
    }
    .itemFrelancer{
      padding: .5rem;
      text-align: justify;
      font-size: 14px;
    
    }
    .questionnoFrelancer{
margin:0rem .5rem 0rem .5rem;

    }
    .faqFrelancerCloseBtn{
      width: 70%;
    }
  
  }
  @media only screen and (min-width:601px ) and (max-width:1000px) {
    .faqFrelancer_Container {
      width: 72%;
      height: 77%;
      /* margin-left: -5rem; */
    }
    .itemFrelancer{
      padding: .7rem;
      text-align: justify;
      font-size: 21px;
    
    }
    .title_headingFrelancer{
      font-size: 21px;
    }
    .questionnoFrelancer{
margin:0rem .5rem 0rem .5rem;

    }
    .faqFrelancerCloseBtn{
      width: 70%;
    }
  
  }