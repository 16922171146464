.Audio_recoder{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border: 3px solid blue; */
}
.audio_btn{
    width: 20%;
    /* border: 3px solid green; */
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
