.createcv_container {
    width: 99.9%;
    height: 100vh;
    display:grid;
    grid-template-columns: 20% 80%;
    align-items: flex-start;
    overflow-y: scroll;
    font-family: "Poppins", sans-serif;
  }
  .sidebarcreatecv {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: sticky;
    top: 0;
    align-items: center;
    background-color: rgba(140,221,220,0.5);
    gap: 2rem;
    padding-top: 3rem;

  }
  .createcv_img{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* border: 2px solid black; */
  }
  .createcv_img img{
    width: 50%;
    height:100%;
    object-fit: cover;
    margin-bottom: .5rem;
    margin-top: .5rem;
  }
  
  .createCV-SideBar {
    width: 100%;
    display: flex;
gap: .3rem;
    flex-direction: column;
    align-items: center;
  }
  .createCV-SideBar img{
    /* border: 2px solid pink; */
    margin-right: 5rem;
  }
  .DropDown-CategoryCV{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* border: 2px solid red; */

  }
  .DropDown-CategoryCV:hover {
    background-color: rgba(255, 255, 255, 0.619);
    color: black;
  }
  .DropDown-CategoryCV-BTN {
    width: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #f7711d00;
    border: none;
    color:#303939;
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0.5rem;
    cursor: pointer;
    outline: none;
  }
  .DropDown-CategoryCV:hover {
    color: black;
  }
  .createcv_btn_grp{
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    gap: .7rem;
    /* margin-right: 5rem; */
    justify-content: flex-start;
    /* width: 100%; */
    
  }