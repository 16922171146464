.HowtoApply {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 1rem;
}
.apply_main_heading {
  font-family: "Poppins", sans-serif;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 38px;
  color: #000000;
}
.apply_main_sub_heading {
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #000000;
}
.HowtoApply .apply-heading {
  font-weight: 600;
  font-size: 40px;
  font-family: "Poppins";
  line-height: 75px;
}
.howtoapply {
  width: 100%;
  height: 20vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 20px;
}
.applay_content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  gap: 3rem;
}
.applay_img_container {
  width: 23%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.loginiget {
  width: 70%;
  background: #3898e2;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0.7rem;
  color: #ffffff;
  text-align: center;
}
.dashboardiget{
  background: #BC84CB;
  width: 70%;
  box-shadow: 0px 4px 20px rgba(0,0,0,0.25);
  border-radius: 10px ;
  padding: .7rem;
  color: #FFFFFF;
  text-align: center;
}
.applyiget{
  width: 70%;
text-align: center;
  background: #56D9B1;
  box-shadow: 0px 4px 20px rgba(0,0,0,0.25);
  border-radius: 10px ;
  padding: .7rem;
  color: #FFFFFF;

}
.apply_inside_img {
  width: 55px;
  height: 50px;
  position: absolute;
  top: 1.8rem;
  left: 1.5rem;
  border-radius: 7px;
  padding: 0.6rem;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  background-color: #ffffff;
}
.Apply-col-1 {
  margin-top: 1rem;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  color: #000000;
}
.Apply-col-1 h3 {
  margin-top: -1.5rem;
  font-size: 25px;
}
.right {
  position: relative;
  bottom: 2rem;
  width: 85%;
}
.right img {
  width: 110%;
}

@media only screen and (min-width: 201px) and (max-width: 500px) {
  .howtoapply {
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
  }
  .applay_content {
    flex-direction: column;
    margin: 2rem;
    gap: 3rem;
  }
  .applay_img_container {
    width: 50%;
    align-items: flex-start;
  }
  .loginiget,
  .applyiget,
  .dashboardiget {
    width: 90%;
    display: flex;
    height: 4rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .apply_inside_img {
    left: -1rem;
  }
}
