.forgotpassword_container1{
    width: 100%;
    height: 100vh;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.forgotpassword_container2{
    width: 50%;
    height: 80%;
    background: white;
    border-radius: 7px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 5px 45px rgba(0, 0, 0, 0.25);
    transition: 0.5s ease-in-out;
    overflow: hidden;
    flex-direction: column;
    gap: 1rem;
}
.forgotheading2{
    display: flex;
    width: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.forgot_input1{
    width: 100%;
}
.otp_container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    gap: 1rem;

}

.rememberpassword_container{
    display: flex;
    width: 60%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}