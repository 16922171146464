.screen {
    display: flex;
    flex-direction: row;
    /* border: 2px solid red; */
}

.coulmnscreen {
    /* border: 2px solid black; */

    display: flex;
    flex-direction: column;
    width: 100%;
    background-position: right bottom;
    background-repeat: no-repeat;
}

.ChooseQuestion {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-image: url('./design.png'); */
    background-repeat: no-repeat;
    background-position: left;
    /* border: 2px solid black; */
}

.Choosequestion {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.chooseqn_addqn {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: .7rem;
}


.chooseqn_addqn .addscreeingqn {
    width: 23%;
    padding: .7rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.addscreeingqn {
    gap: 1rem;
}

.back_log_button {
    width: 100%;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    justify-content: space-between;
    /* align-items: center; */
    /* border: 2px solid red;  */
}

.back_log_button a {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;
    /* border: 2px solid green; */
}

.back_log_button .addtempbb {
    width: 33%;
    padding: .9rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    font-size: 16px;
    /* border: 2px solid red;  */
    /* display: flex;
justify-content: flex-end;
align-items: flex-end;  */


}

.back_log_button .addtempll {
    width: 30%;
    padding: .9rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: none;
    font-size: 16px;
    /* border: 2px solid red;  */
    /* display: flex;
justify-content: flex-end;
align-items: flex-end;  */

}


.Choosequestion .ques-head .chooseqnpara {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 22px;
    display: flex;
    align-items: center;
    color: #000000;
    /* border: 2px solid red; */

}

.ques-body {
    width: 100%;
    display: flex;
    height: 67vh;
    overflow-y: scroll;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    padding-bottom: 10%;
    /* border: 2px solid red; */

}

.screen-ques-body {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 1rem;
    gap: 1rem;
}

.screen-ques-body .ques-body-head input {
    accent-color: rgba(140, 221, 220, 0.5);
    width: 2rem;
    height: 2rem;
    border-color: red;
    border: 1px solid rgba(247, 112, 29, 1);
    border-radius: 50%;
    -webkit-appearance: none;
}

.screen-ques-body .ques-body-msg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 952px;
    padding: 1rem;
    /* height: 162px; */
    cursor: pointer;
    background: rgba(140, 221, 220, 0.5);
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.25);
    border-radius: 15px;
}

.screen-ques-body .ques-body-msg p {
    margin-left: 1rem;
}

@media only screen and (min-width:900px) and (max-width:1280px) {
    .Choosequestion .temp-head {
        width: 100%;
    }

    .ChooseQuestion .parascr {
        font-size: 16px;
    }

    .ques-body {
        width: 90%;
        /* justify-content: center; */
        /* margin-right: 3rem; */
    }

    .ChooseQuestion .parascr {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        text-align: center;

    }

    .chooseqn_addqn .addscreeingqn {
        width: 26%;
    }

    .addscreeingqn {
        gap: .7rem;
    }

    .back_log_button .addtempbb {
        width: 40%;
    }

    .back_log_button .addtempll {
        width: 40%;
    }

    .back_log_button {
        gap: 2.5rem;
        justify-content: center;
    }

    .screen-ques-body .ques-body-msg {
        width: 75%;
    }

    .screen-ques-body {
        gap: 1rem;
    }


}

@media only screen and (min-width:600px) and (max-width:900px) {
    .Choosequestion .temp-head {
        width: 100%;
    }

    .ChooseQuestion .parascr {
        font-size: 16px;
    }

    .ques-body {
        width: 90%;
        height: 57vh;
        /* justify-content: center; */
        /* margin-right: 3rem; */
    }

    .ChooseQuestion .parascr {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        text-align: center;

    }

    .chooseqn_addqn .addscreeingqn {
        width: 46%;
    }

    .addscreeingqn {
        gap: .7rem;
    }

    .back_log_button .addtempbb {
        width: 70%;
    }

    .back_log_button .addtempll {
        width: 70%;
    }

    .back_log_button {
        gap: 2.5rem;
        justify-content: center;
    }

    .screen-ques-body .ques-body-msg {
        width: 75%;
    }

    .screen-ques-body {
        gap: 1rem;
    }













}

@media only screen and (min-width:400px) and (max-width:600px) {
    .Choosequestion .temp-head {
        width: 100%;
    }

    .ChooseQuestion .parascr {
        font-size: 16px;
    }

    .ques-body {
        width: 90%;
        height: 57vh;
        /* justify-content: center; */
        /* margin-right: 3rem; */
    }

    .ChooseQuestion .parascr {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        text-align: center;

    }

    .chooseqn_addqn .addscreeingqn {
        width: 70%;
    }

    .addscreeingqn {
        gap: .7rem;
    }

    .back_log_button .addtempbb {
        width: 80%;
    }

    .back_log_button .addtempll {
        width: 80%;
    }

    .back_log_button {
        gap: 2.5rem;
        justify-content: center;
    }

    .screen-ques-body .ques-body-msg {
        width: 75%;
    }

    .screen-ques-body {
        gap: 1rem;
    }













}

@media only screen and (min-width:200px) and (max-width:400px) {
    .Choosequestion .temp-head {
        width: 100%;
    }

    .ChooseQuestion .parascr {
        font-size: 16px;
    }

    .ques-body {
        width: 90%;
        /* justify-content: center; */
        height: 57vh;
        /* margin-right: 3rem; */
    }

    .ChooseQuestion .parascr {
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000000;
        text-align: center;

    }

    .chooseqn_addqn .addscreeingqn {
        width: 70%;
    }

    .addscreeingqn {
        gap: .7rem;
    }

    .back_log_button .addtempbb {
        width: 80%;
    }

    .back_log_button .addtempll {
        width: 80%;
    }

    .back_log_button {
        gap: 2.5rem;
        justify-content: center;
    }

    .screen-ques-body .ques-body-msg {
        width: 75%;
    }

    .screen-ques-body {
        gap: 1rem;
    }








}